import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const SettingsMenu = ({ onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="settings-menu">
      <FontAwesomeIcon icon={faCog} onClick={toggleMenu} className="settings-icon" />
      {isOpen && (
        <div className="menu">
          <button >Google API</button>
          <button >Kullannıcı Ekle</button>
          <button onClick={onLogout}>Çıkış Yap</button>
          {/* Diğer menü öğeleri buraya eklenebilir */}
        </div>
      )}
    </div>
  );
};

export default SettingsMenu;
