import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { FixedSizeList as List } from 'react-window';
import '../style/VehicleList.css';

const VehicleList = () => {
  const [vehicles, setVehicles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    try {
      const response = await axios.get('/get_vehicles.php');
      if (Array.isArray(response.data)) {
        setVehicles(response.data);
      } else {
        console.error('Unexpected response data format:', response.data);
        setVehicles([]);
      }
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setVehicles([]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredVehicles = vehicles.filter(vehicle =>
    vehicle.plate.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (vehicle.driver_name && vehicle.driver_name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const VehicleRow = ({ index, style }) => {
    const vehicle = filteredVehicles[index];
    return (
      <div
        key={vehicle.id}
        style={{
          ...style,
          top: `${parseFloat(style.top) + 5 * index}px`, // Her öğeye ekstra boşluk ekleyin
          height: style.height - 10, // Her öğenin yüksekliğini ayarlayın
          marginBottom: '10px' // Her öğenin altına boşluk ekleyin
        }}
        className="vehicle-item"
      >
        <div className='plaka-container'>
          <div className='plaka-back'>
            <div className='plaka-left'>
              <p className='plaka-country-text'>TR</p>
            </div>
            <div className='plaka-right'>
              <p className='plaka-text'>{vehicle.plate}</p>
            </div>
          </div>
          <p className='vehicle-seat-info-text'><img width={20} src="/images/icon/yolcu.png" alt="passenger icon" />: {vehicle.passenger_count} / {vehicle.capacity}</p>
        </div>
        
        <div className='plaka-info'>
          <p className='vehicle-driver-info'>Şöför : {vehicle.driver_name || 'Belirtilmedi'} / {vehicle.driver_phone || 'Belirtilmedi'}</p>
          <p className='vehicle-driver-info'>Araç Sahibi : {vehicle.owner_name || 'Belirtilmedi'} / {vehicle.owner_phone || 'Belirtilmedi'}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="vehicle-list-container">
      <input
        type="text"
        placeholder="Plaka veya Şoför Adı ile Ara"
        value={searchTerm}
        onChange={handleSearchChange}
        className="vehicle-search-bar"
      />
      <List
        height={window.innerHeight - 150}
        itemCount={filteredVehicles.length}
        itemSize={105} // Öğenin yüksekliği + boşluk (150 + 10)
        width={"90%"}
      >
        {VehicleRow}
      </List>
    </div>
  );
};

export default VehicleList;
