import React, { useState, useEffect, useRef } from 'react';
import axios from '../axiosConfig';
import { GoogleMap, DirectionsRenderer, useLoadScript } from '@react-google-maps/api';
import Select from 'react-select';
import { FaTimes, FaTrashAlt, FaInfoCircle, FaExchangeAlt } from 'react-icons/fa';
import PassengerTabelaChangeModal from './PassengerTabelaChangeModal';
import '../style/TabelaEditModal.css';

const mapContainerStyle = {
    width: '100%',
    height: '400px',
};

const center = {
    lat: 39.65230096533126,
    lng: 27.89035407376169,
};

const TabelaEditModal = ({ tabela, onClose, onEdit }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const [tabelaName, setTabelaName] = useState(tabela.name);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [tabelas, setTabelas] = useState([]);
    const [passengers, setPassengers] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(tabela.vehicle_id || '');
    const [selectedDriver, setSelectedDriver] = useState(tabela.driver_id || '');
    const [selectedPassengers, setSelectedPassengers] = useState([]);
    const [filteredPassengers, setFilteredPassengers] = useState([]);
    const [directions, setDirections] = useState(null);
    const [highlightedPassenger, setHighlightedPassenger] = useState(null);
    const [selectedPassenger, setSelectedPassenger] = useState(null);
    const [routeName, setRouteName] = useState('');
    const [isTabelaChangeModalOpen, setIsTabelaChangeModalOpen] = useState(false);
    const [passengerToChange, setPassengerToChange] = useState(null);
    const highlightTimeout = useRef(null);

    useEffect(() => {
        fetchVehicles();
        fetchDrivers();
        fetchTabelas();
        fetchPassengers();
        if (selectedVehicle) {
            fetchVehicleRoute(selectedVehicle);
        }
    }, []);

    useEffect(() => {
        setTabelaName(tabela.name);
        setSelectedVehicle(tabela.vehicle_id);
        setSelectedDriver(tabela.driver_id);
        calculateRoute();
    }, [tabela]);

    useEffect(() => {
        if (selectedVehicle) {
            fetchVehicleRoute(selectedVehicle);
        }
        calculateRoute();
    }, [selectedPassengers, selectedVehicle]);

    const fetchVehicles = async () => {
        try {
            const response = await axios.get('/get_vehicles.php');
            setVehicles(response.data);
            console.log('Vehicles:', response.data);
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        }
    };

    const fetchDrivers = async () => {
        try {
            const response = await axios.get('/get_drivers.php');
            setDrivers(response.data);
            console.log('Drivers:', response.data);
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };

    const fetchTabelas = async () => {
        try {
            const response = await axios.get('/get_tabelas.php');
            setTabelas(response.data);
            console.log('Tabelas:', response.data);
        } catch (error) {
            console.error('Error fetching tabelas:', error);
        }
    };

    const fetchPassengers = async () => {
        try {
            const response = await axios.get('/get_passengers.php');
            const passengersData = Array.isArray(response.data) ? response.data : [];
            setPassengers(passengersData);
            console.log('Passengers:', passengersData);

            const tabelaPassengersResponse = await axios.get(`/get_tabela_passengers.php?tabela_id=${tabela.id}`);
            const tabelaPassengersData = Array.isArray(tabelaPassengersResponse.data) ? tabelaPassengersResponse.data : [];
            console.log('Tabela Passengers:', tabelaPassengersData);

            setSelectedPassengers(tabelaPassengersData);
            setFilteredPassengers(tabelaPassengersData);
        } catch (error) {
            console.error('Error fetching passengers:', error);
        }
    };

    const fetchVehicleRoute = async (vehicleId) => {
        const vehicle = vehicles.find(v => v.id === vehicleId);
        if (vehicle) {
            setRouteName(vehicle.route_name || '');
        }
    };

    const calculateRoute = () => {
        if (!isLoaded || !window.google) return;
    
        const waypoints = selectedPassengers.map(c => {
            const lat = parseFloat(c.latitude);
            const lng = parseFloat(c.longitude);
    
            if (isNaN(lat) || isNaN(lng) || lat === 0 || lng === 0) {
                console.error(`Invalid latitude or longitude for passenger: ${c.name}`);
                return null;
            }
    
            return {
                location: { lat, lng },
                stopover: true
            };
        }).filter(w => w !== null);
    
        console.log('Waypoints:', waypoints);
    
        if (waypoints.length > 0) {
            const origin = waypoints[0].location;
            const destination = waypoints[waypoints.length - 1].location;
    
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin,
                    destination,
                    waypoints,
                    optimizeWaypoints: true,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                        console.log('Directions Result:', result);
                    } else {
                        console.error(`Error fetching directions: ${status}`, result);
                    }
                }
            );
        }
    };

    const highlightPassenger = (passengerId) => {
        setHighlightedPassenger(passengerId);
        if (highlightTimeout.current) {
            clearTimeout(highlightTimeout.current);
        }
        highlightTimeout.current = setTimeout(() => {
            setHighlightedPassenger(null);
        }, 2000);
    };

    const removePassenger = async (passenger) => {
        try {
            // Veritabanından yolcuyu sil
            await axios.post('/delete_tabela_customer.php', {
                tabela_id: tabela.id,
                customer_id: passenger.id
            });
    
            // Yolcuyu listeden kaldır
            const updatedPassengers = selectedPassengers.filter(p => p.id !== passenger.id);
            setSelectedPassengers(updatedPassengers);
            console.log('Removed Passenger:', passenger);
    
            // Rotayı yeniden hesapla
            calculateRoute();
        } catch (error) {
            console.error('Error removing passenger:', error);
        }
    };

    const addPassenger = (passenger) => {
        if (!selectedPassengers.some(p => p.id === passenger.id)) {
            setSelectedPassengers([...selectedPassengers, passenger]);
            console.log('Added Passenger:', passenger);
        }
    };

    const handlePassengerSelect = (selectedOption) => {
        const selectedPassenger = passengers.find(p => p.id === selectedOption.value);
        setSelectedPassenger(selectedPassenger);
        console.log('Selected Passenger:', selectedPassenger);
        const isExistingPassenger = selectedPassengers.some(p => p.id === selectedPassenger.id);
        if (isExistingPassenger) {
            highlightPassenger(selectedPassenger.id);
        }
    };

    const handleTabelaChangeClick = (passenger) => {
        setPassengerToChange(passenger);
        setIsTabelaChangeModalOpen(true);
    };

    const handleTabelaChange = async (passengerId, tabelaId) => {
        const updatedPassengers = selectedPassengers.filter(passenger => passenger.id !== passengerId);
        setSelectedPassengers(updatedPassengers);
        setIsTabelaChangeModalOpen(false);
        console.log('Tabela Change:', { passengerId, tabelaId, updatedPassengers });
    };

    const handleEdit = async (event) => {
        event.preventDefault(); // Sayfa yenilenmesini önlemek için eklendi
        try {
            // route_id'yi doğrudan tabela'dan al
            const routeId = tabela.route_id;
            console.log("Updating tabela with data:", {
                id: tabela.id,
                name: tabelaName,
                vehicle_id: selectedVehicle,
                driver_id: selectedDriver,
                route_id: routeId,
                passenger_ids: selectedPassengers.map(p => p.id),
            });
    
            const response = await axios.post('/update_tabela.php', {
                id: tabela.id,
                name: tabelaName,
                vehicle_id: selectedVehicle,
                driver_id: selectedDriver,
                route_id: routeId,
                passenger_ids: selectedPassengers.map(p => p.id),
            });
    
            if (response.data.message === "Tabela updated successfully") {
                console.log("Tabela updated successfully");
                if (onEdit) {
                    onEdit({
                        id: tabela.id,
                        name: tabelaName,
                        vehicle_plate: vehicles.find(v => v.id === selectedVehicle)?.plate || '',
                        driver_name: drivers.find(d => d.id === selectedDriver)?.name || '',
                        route_name: routeName,
                        passenger_count: selectedPassengers.length,
                    });
                }
                onClose(); // Modalı kapat
            } else {
                console.error('Error updating tabela:', response.data.message);
            }
        } catch (error) {
            console.error('Error updating tabela:', error);
        }
    };
    

    if (loadError) return <div>Hata Yüklenemedi</div>;
    if (!isLoaded) return <div>Yükleniyor...</div>;

    const vehicleOptions = vehicles.map(vehicle => ({
        value: vehicle.id,
        label: vehicle.plate
    }));

    const driverOptions = drivers.map(driver => ({
        value: driver.id,
        label: driver.name
    }));

    const passengerOptions = passengers.map(passenger => ({
        value: passenger.id,
        label: `${passenger.name} (${passenger.tabela_name}, ${passenger.route_name})`
    }));

    return (
        <div className="modal-tabela-edit">
            <div className="modal-content-tabela-edit">
                <div className='modal-tabela-edit-header'>
                    <h2 className='modal-tabela-form-heading-text'>{tabelaName} - {drivers.find(d => d.id === selectedDriver)?.name || ''} -
                        {vehicles.find(v => v.id === selectedVehicle)?.plate || ''} - {routeName}
                    </h2>
                    <button className="close-button-tabela-edit" onClick={onClose}>X</button>
                </div>
                <div className='modal-content-tabela-edit-left'>
                    <div className='modal-tabela-edit-tabela-detail'>
                        <form className='modal-tabela-edit-tabela-detail-form-lefttop'>
                            <ul>
                                <h2 className='modal-tabela-form-heading-text'>Tabela Düzenle</h2>
                                <hr style={{ marginBottom: 40 }} />
                                <li>
                                    <label>Tabela Adı:</label>
                                    <input type="text" value={tabelaName} onChange={(e) => setTabelaName(e.target.value)} required />
                                </li>
                                <li>
                                    <label>Araç:</label>
                                    <Select
                                        placeholder='Plaka numarası yazın'
                                        className='modal-tabela-edit-tabela-detail-form-lefttop-select'
                                        value={vehicleOptions.find(option => option.value === selectedVehicle)}
                                        onChange={(selectedOption) => {
                                            setSelectedVehicle(selectedOption.value);
                                            fetchVehicleRoute(selectedOption.value);
                                        }}
                                        options={vehicleOptions}
                                    />
                                </li>
                                <li>
                                    <label>Şoför:</label>
                                    <Select
                                        placeholder='Şoför adı yazın'
                                        className='modal-tabela-edit-tabela-detail-form-lefttop-select'
                                        value={driverOptions.find(option => option.value === selectedDriver)}
                                        onChange={(selectedOption) => setSelectedDriver(selectedOption.value)}
                                        options={driverOptions}
                                    />
                                </li>
                            </ul>
                        </form>
                    </div>
                    <div className='modal-tabela-edit-modal-map-show'>
                        <h2 className='modal-tabela-form-heading-text'>Harita güzergahı</h2>
                        <hr style={{ marginBottom: 15, width: "100%" }} />
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={13}
                            center={center}
                        >
                            {directions && <DirectionsRenderer directions={directions} />}
                        </GoogleMap>
                    </div>
                </div>
                <div className='modal-tabela-edit-modal-passanger-edit'>
                    <form className='modal-tabela-edit-tabela-detail-form-righttop'>
                        <ul>
                            <h2 className='modal-tabela-form-heading-text'>Yolcu Düzenle</h2>
                            <hr style={{ marginBottom: 40 }} />
                            <li>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", marginBottom: 25 }}>
                                    <label>Yolcular:</label>
                                    <div className='modal-tabela-form-table-select'>
                                        <Select
                                            className='modal-tabela-edit-tabela-detail-form-righttop-select'
                                            placeholder='Yolcu ismi yazın'
                                            options={passengerOptions}
                                            onChange={handlePassengerSelect}
                                        />
                                    </div>
                                    {selectedPassenger && !selectedPassengers.some(p => p.id === selectedPassenger.id) && (
                                        <button className="add-button" onClick={() => addPassenger(selectedPassenger)}>Ekle</button>
                                    )}
                                </div>
                            </li>
                            <li>
                                <div className="table-container">
                                    <table className="passengers-table">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>İsim</th>
                                                <th>Adres</th>
                                                <th>İşlem</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedPassengers.map((passenger, index) => (
                                                <tr key={passenger.id} className={highlightedPassenger === passenger.id ? 'highlight' : ''}>
                                                    <td>{index + 1}</td>
                                                    <td>{passenger.name}</td>
                                                    <td>{passenger.address}</td>
                                                    <td style={{minWidth:80}}>
                                                        <button className="icon-button" onClick={(e) => { e.preventDefault(); removePassenger(passenger); }}><FaTrashAlt /></button>
                                                        <button className="icon-button" onClick={(e) => { e.preventDefault(); console.log('Info clicked for', passenger); }}><FaInfoCircle /></button>
                                                        <button className="icon-button" onClick={(e) => { e.preventDefault(); handleTabelaChangeClick(passenger); }}><FaExchangeAlt /></button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>

                <div className='modal-tabela-edit-modal-button-group'>
                    <button type="button" className="button-item-tabela-edit-cancel" onClick={onClose}>İptal</button>
                    <button type="button" className="button-item-tabela-edit-save" onClick={handleEdit}>Kaydet</button>
                </div>
            </div>

            {passengerToChange && (
                <PassengerTabelaChangeModal
                    isOpen={isTabelaChangeModalOpen}
                    onRequestClose={() => setIsTabelaChangeModalOpen(false)}
                    passenger={passengerToChange}
                    tabelas={tabelas}
                    onTabelaChange={handleTabelaChange}
                />
            )}
        </div>
    );
};

export default TabelaEditModal;
