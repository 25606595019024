import React, { useState } from 'react';
import axios from '../axiosConfig';
import '../style/NewDriverModal.css';

const NewDriverModal = ({ onClose }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/add_driver.php', {
        name,
        phone,
      });
      onClose();
    } catch (error) {
      console.error('Error adding driver:', error);
    }
  };

  return (
    <div className="modal-driver">
      <div className="modal-content-driver">
        <h2>Yeni Şoför Ekle</h2>
        <form onSubmit={handleSubmit} className="form-driver">
          <input
            type="text"
            placeholder="İsim"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Telefon"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <div className="button-group-driver">
            <button className="button-item-driver" type="button" onClick={onClose}>İptal</button>
            <button className="button-item-driver" type="submit">Kaydet</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewDriverModal;
