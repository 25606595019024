import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import '../style/CustomerListModal.css';

const CustomerListModal = ({ onClose, onCustomerUpdated }) => {
  const [customers, setCustomers] = useState([]);
  const [editingCustomerId, setEditingCustomerId] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [searchTerms, setSearchTerms] = useState({
    name: '',
    phone: '',
    secondPhone: '',
    class: '',
    address: '',
    routeName: ''
  });
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get('/get_customers.php');
        setCustomers(response.data);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  const handleEditCustomer = (customerId) => {
    setEditingCustomerId(customerId);
    const customer = customers.find((c) => c.id === customerId);
    setEditValues(customer);
  };

  const handleSaveCustomer = async () => {
    try {
      const response = await axios.post('/update_customer.php', editValues);
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer.id === response.data.id ? response.data : customer
        )
      );
      setEditingCustomerId(null);
      onCustomerUpdated(response.data);
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingCustomerId(null);
  };

  const handleDeleteCustomer = async (customerId) => {
    const confirmation = window.confirm("Bu yolcuyu silmek istediğinizden emin misiniz?");
    if (confirmation) {
      try {
        const response = await axios.post('/delete_customer.php', { id: customerId });
        if (response.data.message === "Customer deleted successfully.") {
          setCustomers(customers.filter((customer) => customer.id !== customerId));
        } else if (response.data.message === "Customer is associated with a table and cannot be deleted.") {
          alert("Bu müşteri bir tabela ile ilişkili olduğu için silinemez.");
        } else {
          console.error('Error deleting customer:', response.data.message, response.data.error || '');
        }
      } catch (error) {
        console.error('Error deleting customer:', error);
      }
    }
  };

  const handleSearchChange = (key, value) => {
    setSearchTerms(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const sortByName = () => {
    const sortedCustomers = [...customers].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.name.localeCompare(b.name, undefined, { numeric: true });
      } else {
        return b.name.localeCompare(a.name, undefined, { numeric: true });
      }
    });
    setCustomers(sortedCustomers);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const filteredCustomers = customers.filter(customer => {
    return (
      (customer.name && customer.name.toLowerCase().includes(searchTerms.name.toLowerCase())) &&
      (customer.phone && customer.phone.includes(searchTerms.phone)) &&
      (customer.second_phone && customer.second_phone.includes(searchTerms.secondPhone)) &&
      (customer.class && customer.class.toLowerCase().includes(searchTerms.class.toLowerCase())) &&
      (customer.address && customer.address.toLowerCase().includes(searchTerms.address.toLowerCase())) &&
      (customer.route_name && customer.route_name.toLowerCase().includes(searchTerms.routeName.toLowerCase()))
    ) || (
      searchTerms.name === '' &&
      searchTerms.phone === '' &&
      searchTerms.secondPhone === '' &&
      searchTerms.class === '' &&
      searchTerms.address === '' &&
      searchTerms.routeName === ''
    );
  });

  return (
    <div className="customer-list-modal">
      <div className="modal-content-customer">
        <div className="global-modal-header-container">
          <h2>Yolcu Listesi</h2>
          <span className="global-modal-header-close-button" onClick={onClose}>&times;</span>
        </div>
        <div className='customer-list-modal-table-container'>
          <table className="customer-list-modal-table">
            <thead>
              <tr>
                <th>
                  <div className="th-header">
                    <span>İsim</span>
                    <span className="sort-icon" onClick={sortByName}>&#9650;</span>
                  </div>
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.name}
                    onChange={(e) => handleSearchChange('name', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  <span>Telefon</span>
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.phone}
                    onChange={(e) => handleSearchChange('phone', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  <span>2. Telefon</span>
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.secondPhone}
                    onChange={(e) => handleSearchChange('secondPhone', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  <span>Sınıfı</span>
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.class}
                    onChange={(e) => handleSearchChange('class', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  <span>Adres</span>
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.address}
                    onChange={(e) => handleSearchChange('address', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  <span>Güzergah</span>
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.routeName}
                    onChange={(e) => handleSearchChange('routeName', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer) => (
                <tr key={customer.id} className={editingCustomerId === customer.id ? 'editing-row' : ''}>
                  <td>
                    {editingCustomerId === customer.id ? (
                      <input
                        type="text"
                        value={editValues.name}
                        onChange={(e) => setEditValues({ ...editValues, name: e.target.value })}
                      />
                    ) : (
                      customer.name
                    )}
                  </td>
                  <td>
                    {editingCustomerId === customer.id ? (
                      <input
                        type="text"
                        value={editValues.phone}
                        onChange={(e) => setEditValues({ ...editValues, phone: e.target.value })}
                      />
                    ) : (
                      customer.phone
                    )}
                  </td>
                  <td>
                    {editingCustomerId === customer.id ? (
                      <input
                        type="text"
                        value={editValues.second_phone}
                        onChange={(e) => setEditValues({ ...editValues, second_phone: e.target.value })}
                      />
                    ) : (
                      customer.second_phone
                    )}
                  </td>
                  <td>
                    {editingCustomerId === customer.id ? (
                      <input
                        type="text"
                        value={editValues.class}
                        onChange={(e) => setEditValues({ ...editValues, class: e.target.value })}
                      />
                    ) : (
                      customer.class
                    )}
                  </td>
                  <td>
                    {editingCustomerId === customer.id ? (
                      <input
                        type="text"
                        value={editValues.address}
                        onChange={(e) => setEditValues({ ...editValues, address: e.target.value })}
                      />
                    ) : (
                      customer.address
                    )}
                  </td>
                  <td>
                    {editingCustomerId === customer.id ? (
                      <input
                        type="text"
                        value={editValues.route_name}
                        onChange={(e) => setEditValues({ ...editValues, route_name: e.target.value })}
                      />
                    ) : (
                      customer.route_name
                    )}
                  </td>
                  <td>
                    {editingCustomerId === customer.id ? (
                      <>
                        <button onClick={handleSaveCustomer}>Kaydet</button>
                        <button onClick={handleCancelEdit}>İptal</button>
                      </>
                    ) : (
                      <>
                        <button onClick={() => handleEditCustomer(customer.id)}>Düzenle</button>
                        <button onClick={() => handleDeleteCustomer(customer.id)}>Sil</button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerListModal;
