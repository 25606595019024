import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import '../style/DriverListModal.css';

const DriverListModal = ({ onClose }) => {
  const [drivers, setDrivers] = useState([]);
  const [editingDriver, setEditingDriver] = useState(null);
  const [newName, setNewName] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPhoneTerm, setSearchPhoneTerm] = useState('');

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get('/get_drivers.php');
        setDrivers(response.data);
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    };

    fetchDrivers();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.post('/delete_driver.php', { id });
      setDrivers(drivers.filter(driver => driver.id !== id));
    } catch (error) {
      console.error('Error deleting driver:', error);
    }
  };

  const handleEdit = (driver) => {
    setEditingDriver(driver.id);
    setNewName(driver.name);
    setNewPhone(driver.phone);
  };

  const handleUpdate = async () => {
    try {
      await axios.post('/update_driver.php', { id: editingDriver, name: newName, phone: newPhone });
      setDrivers(drivers.map(driver => driver.id === editingDriver ? { ...driver, name: newName, phone: newPhone } : driver));
      setEditingDriver(null);
      setNewName('');
      setNewPhone('');
    } catch (error) {
      console.error('Error updating driver:', error);
    }
  };

  const filteredDrivers = drivers.filter(driver =>
    driver.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    driver.phone.toLowerCase().includes(searchPhoneTerm.toLowerCase())
  );

  return (
    <div className="modal-driver-list">
      <div className="modal-content-driver-list">
        <div className="global-modal-header-container">
          <h2>Şoför Listesi</h2>
          <span className="global-modal-header-close-button" onClick={onClose}>&times;</span>
        </div>
        <div style={{height:25, width:"100%", backgroundColor:"white"}}></div>
        <table className='table-driver-list'>
          <thead>
            <tr>
              <th className='table-driver-list-th-td'>
                <input
                  type="text"
                  placeholder="Ad Soyad"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="driver-list-search-bar"
                />
              </th>
              <th className='table-driver-list-th-td'>
                <input
                  type="text"
                  placeholder="Telefon"
                  value={searchPhoneTerm}
                  onChange={(e) => setSearchPhoneTerm(e.target.value)}
                  className="driver-list-search-bar"
                />
              </th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredDrivers.map((driver) => (
              <tr
                key={driver.id}
                className={editingDriver === driver.id ? 'editing-row' : ''}
              >
                <td className='table-driver-list-th-td'>
                  {editingDriver === driver.id ? (
                    <input
                      type="text"
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      className="edit-input"
                    />
                  ) : (
                    driver.name
                  )}
                </td>
                <td className='table-driver-list-th-td'>
                  {editingDriver === driver.id ? (
                    <input
                      type="text"
                      value={newPhone}
                      onChange={(e) => setNewPhone(e.target.value)}
                      className="edit-input"
                    />
                  ) : (
                    driver.phone
                  )}
                </td>
                <td className='table-driver-list-th-td'>
                  {editingDriver === driver.id ? (
                    <button onClick={handleUpdate} className="save-button">Kaydet</button>
                  ) : (
                    <button onClick={() => handleEdit(driver)} className="edit-button">Düzenle</button>
                  )}
                  <button onClick={() => handleDelete(driver.id)} className="delete-button">Sil</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DriverListModal;
