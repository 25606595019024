import React, { useState, useRef, useCallback } from 'react';
import { GoogleMap, useLoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import axios from '../axiosConfig';
import '../style/NewRouteModal.css';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '600px',
};
const center = {
  lat: 39.648209,
  lng: 27.882598,
};

const bounds = {
  north: 39.6860,
  south: 39.6100,
  east: 27.9410,
  west: 27.8500,
};

const NewRouteModal = ({ onClose, onAddRoute }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [routeName, setRouteName] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [marker, setMarker] = useState(null);
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);

  const handleMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarker({ lat, lng });

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setLatitude(lat);
        setLongitude(lng);
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  }, []);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setMarker({ lat, lng });
      mapRef.current.panTo({ lat, lng });
      setLatitude(lat);
      setLongitude(lng);
    }
  };

  const handleSubmit = async () => {
    if (routeName.trim() === '') {
      alert("Lütfen güzergah ismini girin.");
      return;
    }

    try {
      const response = await axios.post('/add_route.php', {
        name: routeName,
        latitude,
        longitude
      });

      if (response.data.message === "Route added successfully.") {
        onAddRoute({ id: response.data.id, name: routeName, latitude, longitude });
        onClose();
      } else {
        console.error('Error adding route:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding route:', error);
    }
  };

  if (loadError) return <div>Hata Yüklenemedi</div>;
  if (!isLoaded) return <div>Yükleniyor...</div>;

  return (
    <div className="modal-route">
      <div className="modal-content-route">
        <h2>Yeni Güzergah Ekle</h2>
        <Autocomplete
          onLoad={ref => (autocompleteRef.current = ref)}
          onPlaceChanged={handlePlaceChanged}
          options={{
            bounds: bounds,
            componentRestrictions: { country: 'tr' },
          }}
        >
          <input type="text" placeholder="Güzergah Ara" className="autocomplete-input" />
        </Autocomplete>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={center}
          onClick={handleMapClick}
          onLoad={ref => (mapRef.current = ref)}
        >
          {marker && <Marker position={marker} />}
        </GoogleMap>
        <input
          type="text"
          placeholder="Güzergah İsmi"
          value={routeName}
          onChange={(e) => setRouteName(e.target.value)}
          required
        />
        <div className="button-group">
          <button className="button-item" onClick={onClose}>İptal</button>
          <button className="button-item" onClick={handleSubmit}>Ekle</button>
        </div>
      </div>
    </div>
  );
};

export default NewRouteModal;
