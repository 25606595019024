import React, { useState } from 'react';
import NewCustomerModal from './NewCustomerModal';
import CustomerListModal from './CustomerListModal';
import NewDriverModal from './NewDriverModal';
import DriverListModal from './DriverListModal';
import NewVehicleModal from './NewVehicleModal';
import VehicleListModal from './VehicleListModal';
import TabelaList from './TabelaList';
import TabelaCreateModal from './TabelaCreateModal';
import TabelaEditModal from './TabelaEditModal';
import ImportFromExcelModal from './ImportFromExcelModal';
import PasteFromExcelModal from './PasteFromExcelModal';
import '../style/SearchBar.css';

const SearchBar = ({ onCustomerAdded, onCustomerUpdated }) => {
  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const [showCustomerListModal, setShowCustomerListModal] = useState(false);
  const [showNewDriverModal, setShowNewDriverModal] = useState(false);
  const [showDriverListModal, setShowDriverListModal] = useState(false);
  const [showNewVehicleModal, setShowNewVehicleModal] = useState(false);
  const [showVehicleListModal, setShowVehicleListModal] = useState(false);
  const [showTabelaListModal, setShowTabelaListModal] = useState(false);
  const [showTabelaCreateModal, setShowTabelaCreateModal] = useState(false);
  const [showImportFromExcelModal, setShowImportFromExcelModal] = useState(false);
  const [showPasteFromExcelModal, setShowPasteFromExcelModal] = useState(false);
  const [showTabelaEditModal, setShowTabelaEditModal] = useState(false);
  const [selectedTabela, setSelectedTabela] = useState(null);

  const handleAddCustomerClick = () => {
    setShowNewCustomerModal(true);
  };

  const handleCloseNewCustomerModal = () => {
    setShowNewCustomerModal(false);
  };

  const handleCustomerListClick = () => {
    setShowCustomerListModal(true);
  };

  const handleCloseCustomerListModal = () => {
    setShowCustomerListModal(false);
  };

  const handleAddDriverClick = () => {
    setShowNewDriverModal(true);
  };

  const handleCloseNewDriverModal = () => {
    setShowNewDriverModal(false);
  };

  const handleDriverListClick = () => {
    setShowDriverListModal(true);
  };

  const handleCloseDriverListModal = () => {
    setShowDriverListModal(false);
  };

  const handleAddVehicleClick = () => {
    setShowNewVehicleModal(true);
  };

  const handleCloseNewVehicleModal = () => {
    setShowNewVehicleModal(false);
  };

  const handleVehicleListClick = () => {
    setShowVehicleListModal(true);
  };

  const handleCloseVehicleListModal = () => {
    setShowVehicleListModal(false);
  };

  const handleTabelaCreateClick = () => {
    setShowTabelaCreateModal(true);
  };

  const handleCloseTabelaCreateModal = () => {
    setShowTabelaCreateModal(false);
  };

  const handleTabelaListClick = () => {
    setShowTabelaListModal(true);
  };

  const handleCloseTabelaListModal = () => {
    setShowTabelaListModal(false);
  };

  const handleImportFromExcelClick = () => {
    setShowImportFromExcelModal(true);
  };

  const handleCloseImportFromExcelModal = () => {
    setShowImportFromExcelModal(false);
  };

  const handlePasteFromExcelClick = () => {
    setShowPasteFromExcelModal(true);
  };

  const handleClosePasteFromExcelModal = () => {
    setShowPasteFromExcelModal(false);
  };

  const handleTabelaEdit = (tabela) => {
    setSelectedTabela(tabela);
    setShowTabelaEditModal(true);
  };

  const handleCloseTabelaEditModal = () => {
    setShowTabelaEditModal(false);
  };

  return (
    <div className="search-bar">
      <input className='search-bar-input' type="text" placeholder="Semt, Bölge, Plaka, Müşteri" />
      <button className='search-bar-button'>BUL</button>
      <div className="dropdown">
        <button className="dropbtn">Tabela İşlemleri</button>
        <div className="dropdown-content">
          {/* <button className='dropdown-content-button' onClick={handleTabelaCreateClick}>Tabela Oluştur</button> */}
          <button className='dropdown-content-button' onClick={handleTabelaListClick}>Tabela Listesi</button>
          {/* <button className='dropdown-content-button' onClick={handleImportFromExcelClick}>Dosyadan Aktar</button> */}
          <button className='dropdown-content-button-excel' onClick={handlePasteFromExcelClick}>Excel'den Yapıştır</button>
        </div>
      </div>
      <div className="dropdown">
        <button className="dropbtn">Yolcu İşlemleri</button>
        <div className="dropdown-content">
          <button className='dropdown-content-button' onClick={handleAddCustomerClick}>Yolcu Ekle</button>
          <button className='dropdown-content-button' onClick={handleCustomerListClick}>Yolcu Listesi</button>
        </div>
      </div>
      <div className="dropdown">
        <button className="dropbtn">Şoför İşlemleri</button>
        <div className="dropdown-content">
          <button className='dropdown-content-button' onClick={handleAddDriverClick}>Şoför Ekle</button>
          <button className='dropdown-content-button' onClick={handleDriverListClick}>Şoför Listesi</button>
        </div>
      </div>
      <div className="dropdown">
        <button className="dropbtn">Araç İşlemleri</button>
        <div className="dropdown-content">
          <button className='dropdown-content-button' onClick={handleAddVehicleClick}>Araç Ekle</button>
          <button className='dropdown-content-button' onClick={handleVehicleListClick}>Araç Listesi</button>
        </div>
      </div>
      {showNewCustomerModal && (
        <NewCustomerModal
          onClose={handleCloseNewCustomerModal}
          onCustomerAdded={onCustomerAdded}
        />
      )}
      {showCustomerListModal && (
        <CustomerListModal
          onClose={handleCloseCustomerListModal}
          onCustomerUpdated={onCustomerUpdated}
        />
      )}
      {showNewDriverModal && (
        <NewDriverModal
          onClose={handleCloseNewDriverModal}
        />
      )}
      {showDriverListModal && (
        <DriverListModal
          onClose={handleCloseDriverListModal}
        />
      )}
      {showNewVehicleModal && (
        <NewVehicleModal
          onClose={handleCloseNewVehicleModal}
        />
      )}
      {showVehicleListModal && (
        <VehicleListModal
          onClose={handleCloseVehicleListModal}
        />
      )}
      {showTabelaCreateModal && (
        <TabelaCreateModal onClose={handleCloseTabelaCreateModal} />
      )}
      {showTabelaListModal && (
        <TabelaList onClose={handleCloseTabelaListModal} onTabelaEdit={handleTabelaEdit} />
      )}
      {showImportFromExcelModal && (
        <ImportFromExcelModal onClose={handleCloseImportFromExcelModal} />
      )}
      {showPasteFromExcelModal && (
        <PasteFromExcelModal onClose={handleClosePasteFromExcelModal} /> 
      )}
      {showTabelaEditModal && selectedTabela && (
        <TabelaEditModal tabela={selectedTabela} onClose={handleCloseTabelaEditModal} />
      )}
    </div>
  );
};

export default SearchBar;
