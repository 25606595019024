import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Select from 'react-select';
import '../style/PasteFromExcelModal.css';
import NewVehicleModal from './NewVehicleModal';
import NewRouteModal from './NewRouteModal';

const PasteFromExcelModal = ({ onClose }) => {
  const [pasteData, setPasteData] = useState('');
  const [previewData, setPreviewData] = useState([]);
  const [tabelaName, setTabelaName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerPhone, setOwnerPhone] = useState('');
  const [driverName, setDriverName] = useState('');
  const [driverPhone, setDriverPhone] = useState('');
  const [route, setRoute] = useState('');
  const [academicYear, setAcademicYear] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedReturnVehicle, setSelectedReturnVehicle] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [showNewVehicleModal, setShowNewVehicleModal] = useState(false);
  const [showNewRouteModal, setShowNewRouteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [isRouteRequired, setIsRouteRequired] = useState(false);

  const [columnMappings, setColumnMappings] = useState({
    class: 'Öğrenci Sınıfı',
    bloodType: 'Kan Grubu',
    address: 'Adres',
    phone: 'Telefon',
    secondPhone: 'İkinci Telefon',
    name: 'İsim Soyisim',
  });

  const [visibleColumns, setVisibleColumns] = useState({
    class: true,
    bloodType: true,
    address: true,
    phone: true,
    secondPhone: false,
    name: true,
  });

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setAcademicYear(`${currentYear - 1}/${currentYear}`);
    fetchVehicles();
    fetchRoutes();
  }, []);

  const fetchVehicles = async () => {
    try {
      const response = await axios.get('/get_vehicles.php');
      setVehicles(response.data);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const fetchRoutes = async () => {
    try {
      const response = await axios.get('/get_routes.php');
      setRoutes(response.data);
    } catch (error) {
      console.error('Error fetching routes:', error);
    }
  };

  const handlePasteDataChange = (e) => {
    setPasteData(e.target.value);
  };

  const handleParseData = () => {
    const visibleColumnKeys = Object.keys(columnMappings).filter(column => visibleColumns[column]);
    const rows = pasteData.split('\n').map(row => row.split('\t'));
    const formattedData = rows.map((row, index) => {
      const rowData = {};
      visibleColumnKeys.forEach((column, columnIndex) => {
        rowData[column] = row[columnIndex] || '';
      });
      return { id: index + 1, ...rowData };
    });
    setPreviewData(formattedData);
  };

  const handleVehicleChange = (selectedOption) => {
    if (selectedOption) {
      const selectedVehicle = vehicles.find(vehicle => vehicle.id === selectedOption.value);
      if (selectedVehicle) {
        setOwnerName(selectedVehicle.owner_name);
        setOwnerPhone(selectedVehicle.owner_phone);
        setDriverName(selectedVehicle.driver_name);
        setDriverPhone(selectedVehicle.driver_phone);
        setRoute(selectedVehicle.route);
        setSelectedVehicle(selectedVehicle);
      }
    } else {
      setOwnerName('');
      setOwnerPhone('');
      setDriverName('');
      setDriverPhone('');
      setRoute('');
      setSelectedVehicle(null);
    }
  };

  const handleReturnVehicleChange = (selectedOption) => {
    if (selectedOption) {
      const selectedReturnVehicle = vehicles.find(vehicle => vehicle.id === selectedOption.value);
      if (selectedReturnVehicle) {
        setSelectedReturnVehicle(selectedReturnVehicle);
      }
    } else {
      setSelectedReturnVehicle(null);
    }
  };

  const handleRouteChange = (selectedOption) => {
    setSelectedRoute(selectedOption);
    setIsRouteRequired(!selectedOption); // Eğer seçili seçenek varsa zorunlu değil
  };

  const handleSave = async () => {
    if (!selectedRoute) {
      setIsRouteRequired(true);
      return;
    }
    
    setLoading(true);
    try {
      console.log('Başladı: Öğrenci verilerini kaydetme');

      const customerResponses = await Promise.all(previewData.map(async (student) => {
        const response = await axios.post('/add_customer.php', {
          name: student.name,
          phone: student.phone,
          second_phone: student.secondPhone,
          address: student.address,
          class: student.class,
          bloodType: student.bloodType,
          latitude: '',
          longitude: '',
          customer_type: 'Öğrenci',
          route_id: selectedRoute ? selectedRoute.value : null
        });
        console.log('Eklendi: Öğrenci', response.data);
        return response.data.customer.id;
      }));

      console.log('Eklendi: Tüm öğrenciler', customerResponses);

      const tabelaData = {
        tabela_name: tabelaName,
        vehicle_id: selectedVehicle ? selectedVehicle.id : null,
        return_vehicle_id: selectedReturnVehicle ? selectedReturnVehicle.id : null,
        driver_id: selectedVehicle ? selectedVehicle.driver_id : null,
        return_driver_id: selectedReturnVehicle ? selectedReturnVehicle.driver_id : null,
        route_id: selectedRoute ? selectedRoute.value : null
      };

      console.log('Tabela Data:', tabelaData);

      const tabelaResponse = await axios.post('/add_tabela.php', tabelaData);
      const tabelaId = tabelaResponse.data.id;

      console.log('Eklendi: Tabela', tabelaId);

      const tabelaCustomersResponse = await axios.post('/add_tabela_customers.php', {
        tabela_id: tabelaId,
        passengers: customerResponses
      });

      console.log('Eklendi: Tabela yolcuları', tabelaCustomersResponse.data);

      setTimeout(() => {
        setLoading(false);
        onClose();
      }, 1000);
    } catch (error) {
      console.error('Error saving data:', error);
      setLoading(false);
    }
  };

  const handleNewVehicleClick = () => {
    setShowNewVehicleModal(true);
  };

  const handleCloseNewVehicleModal = () => {
    setShowNewVehicleModal(false);
    fetchVehicles();
  };

  const handleNewRouteClick = () => {
    setShowNewRouteModal(true);
  };

  const handleCloseNewRouteModal = () => {
    setShowNewRouteModal(false);
    fetchRoutes();
  };

  const handleAddRoute = (newRoute) => {
    setRoutes([...routes, newRoute]);
    setSelectedRoute(newRoute);
    setShowNewRouteModal(false);
  };

  useEffect(() => {
    if (route) {
      const matchingVehicles = vehicles.filter(vehicle => vehicle.route && vehicle.route.includes(route));
      setFilteredVehicles(matchingVehicles);
    } else {
      setFilteredVehicles([]);
    }
  }, [route, vehicles]);

  const vehicleOptions = vehicles.map(vehicle => ({
    value: vehicle.id,
    label: `${vehicle.plate} - ${vehicle.capacity} - ${vehicle.driver_name} (${vehicle.passenger_count}/${vehicle.capacity.split('+')[0]})`
  }));

  const routeOptions = routes.map(route => ({
    value: route.id,
    label: route.name
  }));

  const handleColumnVisibilityChange = (column) => {
    setVisibleColumns(prevState => ({
      ...prevState,
      [column]: !prevState[column]
    }));
  };

  return (
    <div className="paste-from-excel-modal">
      <div className='paste-from-modal-right-vehicles'>
        <div>
          {filteredVehicles.length > 0 ? (
            <ul className='paste-from-modal-right-vehicles-ul'>
              {filteredVehicles.map(vehicle => (
                <li key={vehicle.id} className='paste-from-modal-right-vehicles-li'>
                  <h4>{vehicle.plate}</h4>
                  <p><b>Şoför :</b> {vehicle.driver_name}</p>
                  <p><b>Tel :</b> {vehicle.driver_phone}</p>
                  <p><b>Sahibi :</b> {vehicle.owner_name}</p>
                  <p><b>Tel :</b> {vehicle.owner_phone}</p>
                  <p><b>Güzergah :</b> {vehicle.route}</p>
                  <p><b>Kapasite :</b> {vehicle.capacity} / {vehicle.passenger_count}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>.</p>
          )}
        </div>
      </div>
      <div className="paste-from-modal-content">
        {loading && <div className="loading-overlay">Kaydediliyor...</div>}
        <span className="paste-from-close" onClick={onClose}>&times;</span>
        <h2 className="paste-from-h2">Excel'den Yapıştır</h2>
        <div className="checkbox-container">
          {Object.keys(columnMappings).map(column => (
            <label key={column}>
              <input
                type="checkbox"
                checked={visibleColumns[column]}
                onChange={() => handleColumnVisibilityChange(column)}
              />
              {columnMappings[column]}
            </label>
          ))}
        </div>
        <textarea
          className="paste-from-textarea"
          placeholder="Excel'den kopyaladığınız verileri buraya yapıştırın"
          value={pasteData}
          onChange={handlePasteDataChange}
        />
        <button className="paste-excel-top-button" onClick={handleParseData}>Veriyi İşle</button>
        {previewData.length > 0 && (
          <div>
            <h3 className="paste-from-h3">Ön İzleme</h3>
            <div className="paste-excel-modal-input-container">
              <div className='paste-excel-modal-input-zone'>
                <input
                  type="text"
                  placeholder="Tabela İsmi"
                  value={tabelaName}
                  onChange={(e) => setTabelaName(e.target.value)}
                />
                <div className="vehicle-select-container">
                  <Select
                    options={vehicleOptions}
                    placeholder="Araç Seçin"
                    onChange={handleVehicleChange}
                    isClearable
                    className='paste-excel-select'
                  />
                  <button className="paste-from-button" onClick={handleNewVehicleClick}>
                    +
                  </button>
                </div>
              </div>
              <div className='paste-excel-modal-input-zone'>
                <div className="vehicle-select-container">
                  <Select
                    options={vehicleOptions}
                    placeholder="Dönüş Aracı Seçin (zorunlu değil)"
                    onChange={handleReturnVehicleChange}
                    isClearable
                    className='paste-excel-select'
                  />
                  <button className="paste-from-button" onClick={handleNewVehicleClick}>
                    +
                  </button>
                </div>
                <div className="vehicle-select-container">
                  <Select
                    options={routeOptions}
                    placeholder="Hedef Konum Seçin"
                    onChange={handleRouteChange}
                    isClearable
                    className={`paste-excel-select ${isRouteRequired ? 'required-field' : ''}`}
                  />
                  {isRouteRequired && <span className="error-text">Lütfen bir konum seçin.</span>}
                  <button className="paste-from-button" onClick={handleNewRouteClick}>
                    +
                  </button>
                </div>
              </div>
              <div className='paste-excel-modal-input-zone'>
                <input
                  type="text"
                  placeholder="Güzergah"
                  value={route}
                  onChange={(e) => setRoute(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Araç Sahibinin Adı"
                  value={ownerName}
                  onChange={(e) => setOwnerName(e.target.value)}
                />
              </div>
              <div className='paste-excel-modal-input-zone'>
                <input
                  type="text"
                  placeholder="Araç Sahibinin Telefonu"
                  value={ownerPhone}
                  onChange={(e) => setOwnerPhone(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Şoförün Adı"
                  value={driverName}
                  onChange={(e) => setDriverName(e.target.value)}
                />
              </div>
              <div className='paste-excel-modal-input-zone'>
                <input
                  type="text"
                  placeholder="Şoförün Telefonu"
                  value={driverPhone}
                  onChange={(e) => setDriverPhone(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Öğretim Yılı"
                  value={academicYear}
                  onChange={(e) => setAcademicYear(e.target.value)}
                />
              </div>
              <button className="paste-excel-bottom-button" onClick={handleSave}>Kaydet</button>
            </div>
            <table className="paste-from-table">
              <thead>
                <tr>
                  <th>#</th>
                  {visibleColumns.class && <th>{columnMappings.class}</th>}
                  {visibleColumns.bloodType && <th>{columnMappings.bloodType}</th>}
                  {visibleColumns.address && <th>{columnMappings.address}</th>}
                  {visibleColumns.phone && <th>{columnMappings.phone}</th>}
                  {visibleColumns.secondPhone && <th>{columnMappings.secondPhone}</th>}
                  {visibleColumns.name && <th>{columnMappings.name}</th>}
                </tr>
              </thead>
              <tbody>
                {previewData.map(row => (
                  <tr key={row.id}>
                    <td>{row.id}</td>
                    {visibleColumns.class && <td>{row.class}</td>}
                    {visibleColumns.bloodType && <td>{row.bloodType}</td>}
                    {visibleColumns.address && <td>{row.address}</td>}
                    {visibleColumns.phone && <td>{row.phone}</td>}
                    {visibleColumns.secondPhone && <td>{row.secondPhone}</td>}
                    {visibleColumns.name && <td>{row.name}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
            
          </div>
        )}
        {showNewVehicleModal && (
          <NewVehicleModal onClose={handleCloseNewVehicleModal} />
        )}
        {showNewRouteModal && (
          <NewRouteModal onClose={handleCloseNewRouteModal} onAddRoute={handleAddRoute} />
        )}
      </div>
    </div>
  );
};

export default PasteFromExcelModal;
