import React, { useState, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import '../style/MapModal.css';

const libraries = ['places'];
const mapContainerStyle = {
  width: '500px',
  height: '500px',
};
const center = {
  lat: 39.65230096533126,
  lng: 27.89035407376169,
};

const bounds = {
  north: 39.7860,  // Balıkesir kuzey sınırı
  south: 39.5200,  // Balıkesir güney sınırı
  east: 28.0000,   // Balıkesir doğu sınırı
  west: 27.7000,   // Balıkesir batı sınırı
};

const MapModal = ({ onClose, onSelectAddress }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState('');
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);

  if (loadError) return <div>Hata Yüklenemedi</div>;
  if (!isLoaded) return <div>Yükleniyor...</div>;

  const getAddressComponents = (place) => {
    const addressComponents = place.address_components;
    console.log('Adres bileşenleri:', addressComponents); // Adres bileşenlerini kontrol edin.
    let streetNumber = '';
    let route = '';
    let neighborhood = '';
    let administrativeAreaLevel4 = '';

    addressComponents.forEach(component => {
      if (component.types.includes('street_number')) {
        streetNumber = component.long_name;
      }
      if (component.types.includes('route')) {
        route = component.long_name;
      }
      if (component.types.includes('neighborhood')) {
        neighborhood = component.long_name;
      }
      if (component.types.includes('administrative_area_level_4')) {
        administrativeAreaLevel4 = component.long_name;
      }
    });

    return `${administrativeAreaLevel4 || neighborhood}, ${route}, No:${streetNumber}`;
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarker({ lat, lng });

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const formattedAddress = getAddressComponents(results[0]);
        setAddress(formattedAddress);
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  };

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place || !place.geometry) {
      console.error('Geçersiz yer seçimi');
      return;
    }
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setMarker({ lat, lng });
    mapRef.current.panTo({ lat, lng });

    const formattedAddress = getAddressComponents(place);
    setAddress(formattedAddress);
  };

  const handleSave = () => {
    if (marker) {
      onSelectAddress({ address, lat: marker.lat, lng: marker.lng });
      onClose();
    }
  };

  return (
    <div className="modal">
      <div className="map-modal-content">
        <h2>Adres Seç</h2>
        <Autocomplete
          onLoad={ref => (autocompleteRef.current = ref)}
          onPlaceChanged={handlePlaceChanged}
          options={{
            bounds: bounds,
            componentRestrictions: { country: 'tr' },
          }}
        >
          <input type="text" placeholder="Adres Ara" className="map-modal-autocomplete-input" />
        </Autocomplete>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={center}
          onClick={handleMapClick}
          onLoad={ref => (mapRef.current = ref)}
        >
          {marker && <Marker position={marker} />}
        </GoogleMap>
        <div className='map-modal-selected-address'>
          <p>Seçilen Adres: {address}</p>
        </div>

        <div className="modal-forms-bottom-yes-or-no-button-group">
          <button className="modal-forms-bottom-yes-or-no-button-group-no" onClick={onClose}>İptal</button>
          <button className="modal-forms-bottom-yes-or-no-button-group-yes" onClick={handleSave}>Kaydet</button>
        </div>
      </div>
    </div>
  );
};

export default MapModal;
