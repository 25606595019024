import React, { useState } from 'react';
import { read, utils } from 'xlsx';
import '../style/ImportFromExcelModal.css';

const ImportFromExcelModal = ({ onClose }) => {
  const [file, setFile] = useState(null);
  const [previewData, setPreviewData] = useState({});
  const [selectedSchool, setSelectedSchool] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('Selected file:', file);
    setFile(file);
  };

  const handleFileRead = () => {
    if (!file) {
      alert('Lütfen bir dosya seçin.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });
      console.log('Workbook:', workbook);

      const schoolsData = {};
      workbook.SheetNames.forEach(sheetName => {
        const sheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(sheet, { header: 1 });
        const schoolName = sheetName || (jsonData[0] && jsonData[0][0]);
        
        // İlk satırları başlık olarak kabul edip verileri alıyoruz
        const dataStartIndex = jsonData.findIndex(row => row.includes('S.NO'));
        const dataRows = jsonData.slice(dataStartIndex + 1).filter(row => row.length > 1 && !row.includes('TOPLAM') && !row.includes('HAKEDİŞ') && !row.includes('S.NO'));
        
        const formattedData = dataRows.map((row, index) => ({
          id: index + 1,
          tabelaNo: row[0],
          plaka: row[1],
          aracSahibiAdi: '', // Boş bırakılıyor
          aracSahibiTelefonu: '', // Boş bırakılıyor
          soforAdi: '', // Boş bırakılıyor
          soforTelefonu: '', // Boş bırakılıyor
          guzergah: '', // Boş bırakılıyor
          toplamYolcu: row[7]
        }));

        schoolsData[schoolName] = formattedData;
      });

      setPreviewData(schoolsData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSave = () => {
    console.log('Saving data:', previewData);
    onClose();
  };

  return (
    <div className="import-from-excel-modal">
      <div className="import-from-modal-content">
        <span className="import-from-close" onClick={onClose}>&times;</span>
        <h2 className="import-from-h2">Excel Dosyasından Aktar</h2>
        <input className="import-from-input" type="file" onChange={handleFileChange} />
        <button className="import-from-button" onClick={handleFileRead}>Dosyayı Oku</button>
        {Object.keys(previewData).length > 0 && (
          <div>
            <h3 className="import-from-h3">Okullar</h3>
            <div className="tabs">
              {Object.keys(previewData).map(school => (
                <button
                  key={school}
                  className={`tab-button ${selectedSchool === school ? 'active' : ''}`}
                  onClick={() => setSelectedSchool(school)}
                >
                  {school}
                </button>
              ))}
            </div>
            {selectedSchool && (
              <div>
                <h3 className="import-from-h3">{selectedSchool}</h3>
                <table className="import-from-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Tabela No</th>
                      <th>Plaka No</th>
                      <th>Araç Sahibinin Adı</th>
                      <th>Araç Sahibinin Telefonu</th>
                      <th>Şoförün Adı</th>
                      <th>Şoförün Telefonu</th>
                      <th>Güzergah</th>
                      <th>Toplam Yolcu</th>
                    </tr>
                  </thead>
                  <tbody>
                    {previewData[selectedSchool].map(row => (
                      <tr key={row.id}>
                        <td>{row.id}</td>
                        <td>{row.tabelaNo}</td>
                        <td>{row.plaka}</td>
                        <td>{row.aracSahibiAdi}</td>
                        <td>{row.aracSahibiTelefonu}</td>
                        <td>{row.soforAdi}</td>
                        <td>{row.soforTelefonu}</td>
                        <td>{row.guzergah}</td>
                        <td>{row.toplamYolcu}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <button className="import-from-button" onClick={handleSave}>Kaydet</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportFromExcelModal;
