import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from '../axiosConfig';
import '../style/PassengerTabelaChangeModal.css';

const PassengerTabelaChangeModal = ({ isOpen, onRequestClose, passenger, tabelas, onTabelaChange }) => {
    const [selectedTabela, setSelectedTabela] = useState(null);

    useEffect(() => {
        console.log('Passenger:', passenger);
        console.log('Tabelas:', tabelas);
    }, [passenger, tabelas]);

    const handleTabelaChange = async (event) => {
        event.preventDefault(); // Sayfa yenilenmesini önlemek için eklendi
        console.log('Selected Tabela:', selectedTabela);
        try {
            const response = await axios.post('/change_passenger_tabela.php', {
                passenger_id: passenger.id,
                tabela_id: selectedTabela.value,
            });
            console.log('Response:', response.data);
            onTabelaChange(passenger.id, selectedTabela.value);
            onRequestClose();
        } catch (error) {
            console.error('Error changing passenger tabela:', error);
        }
    };

    const tabelaOptions = tabelas.map(tabela => ({
        value: tabela.id,
        label: tabela.name
    }));

    return (
        <div className={`passenger-modal ${isOpen ? 'is-active' : ''}`}>
            <div className="passenger-modal-background" onClick={onRequestClose}></div>
            <div className="passenger-modal-content">
                <div className="passenger-modal-box">
                    <h2 className="passenger-modal-title">{passenger.name} - Mevcut Tabela: {passenger.tabela_name}</h2>
                    <Select
                        placeholder='Yeni tabela seçin'
                        options={tabelaOptions}
                        onChange={setSelectedTabela}
                    />
                    <button className="passenger-modal-button is-primary" onClick={handleTabelaChange}>Kaydet</button>
                    <button className="passenger-modal-button" onClick={onRequestClose}>İptal</button>
                </div>
            </div>
            <button className="passenger-modal-close is-large" aria-label="close" onClick={onRequestClose}></button>
        </div>
    );
};

export default PassengerTabelaChangeModal;
