import React, { useState, useEffect, useRef } from 'react';
import axios from '../axiosConfig';
import { FaEdit, FaTrashAlt, FaPrint, FaAngleDoubleLeft, FaAngleDoubleRight, FaTimes, FaSortAlphaDown } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { generateTabelaExcel } from './TabelaExcelGenerator';
import '../style/TabelaList.css';

const TabelaList = ({ onClose, onTabelaEdit }) => {
    const [tabelas, setTabelas] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [searchTerms, setSearchTerms] = useState({
        tabelaName: '',
        vehiclePlate: '',
        driverName: '',
        routeName: '',
        driverPhone: '',
        passengerCount: '',
        returnVehiclePlate: '',
        returnDriverName: ''
    });
    const [sortOrder, setSortOrder] = useState('asc'); // Sıralama düzeni için state

    const routeTabsRef = useRef(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    useEffect(() => {
        fetchRoutes();
        fetchVehicles();
        fetchDrivers();
        fetchTabelas();
    }, []);

    const fetchRoutes = async () => {
        try {
            const response = await axios.get('/get_routes.php');
            const data = Array.isArray(response.data) ? response.data : [];
            setRoutes(data);
        } catch (error) {
            console.error('Error fetching routes:', error);
        }
    };

    const fetchVehicles = async () => {
        try {
            const response = await axios.get('/get_vehicles.php');
            const data = Array.isArray(response.data) ? response.data : [];
            setVehicles(data);
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        }
    };

    const fetchDrivers = async () => {
        try {
            const response = await axios.get('/get_drivers.php');
            const data = Array.isArray(response.data) ? response.data : [];
            setDrivers(data);
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };

    const fetchTabelas = async () => {
        try {
            const response = await axios.get('/get_tabelas.php');
            const data = Array.isArray(response.data) ? response.data : [];
            setTabelas(data);
        } catch (error) {
            console.error('Error fetching tabelas:', error);
        }
    };

    const handleDeleteTabela = async (tabelaId) => {
        try {
            const response = await axios.post('/delete_tabela.php', { id: tabelaId });
            if (response.data.message === "Tabela deleted successfully") {
                setTabelas(tabelas.filter(tabela => tabela.id !== tabelaId));
            } else {
                console.error('Error deleting tabela:', response.data.message);
            }
        } catch (error) {
            console.error('Error deleting tabela:', error);
        }
    };

    const confirmDelete = (tabelaId) => {
        confirmAlert({
            title: 'Onayla',
            message: 'Bu tabelayı silmek istediğinize emin misiniz?',
            buttons: [
                {
                    label: 'Evet',
                    onClick: () => handleDeleteTabela(tabelaId)
                },
                {
                    label: 'Hayır',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleRouteClick = (routeId) => {
        setSelectedRoute(routeId);
    };

    const clearRouteFilter = () => {
        setSelectedRoute(null);
    };

    const handleSearchChange = (key, value) => {
        setSearchTerms(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const sortTabelaName = () => {
        const sortedTabelas = [...tabelas].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.name.localeCompare(b.name, undefined, { numeric: true });
            } else {
                return b.name.localeCompare(a.name, undefined, { numeric: true });
            }
        });
        setTabelas(sortedTabelas);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const getVehicleCapacity = (vehicleId) => {
        const vehicle = vehicles.find(v => v.id === vehicleId);
        return vehicle ? vehicle.capacity : 'Bilinmiyor';
    };

    const getVehicleRoute = (vehicleId) => {
        const vehicle = vehicles.find(v => v.id === vehicleId);
        return vehicle ? vehicle.route : 'Bilinmiyor';
    };

    const getVehiclePlate = (vehicleId) => {
        const vehicle = vehicles.find(v => v.id === vehicleId);
        return vehicle ? vehicle.plate : 'BELİRTİLMEDİ';
    };

    const getDriverName = (driverId) => {
        const driver = drivers.find(d => d.id === driverId);
        return driver ? driver.name : 'BELİRTİLMEDİ';
    };

    const getDriverPhone = (driverId) => {
        const driver = drivers.find(d => d.id === driverId);
        return driver ? driver.phone : 'BELİRTİLMEDİ';
    };

    const filteredTabelas = tabelas.filter(tabela => {
        const vehicle = vehicles.find(v => v.id === tabela.vehicle_id);
        const routeMatch = selectedRoute ? tabela.route_id === selectedRoute : true;
        const searchMatches = [
            tabela.name.toLowerCase().includes(searchTerms.tabelaName.toLowerCase()),
            tabela.vehicle_plate.toLowerCase().includes(searchTerms.vehiclePlate.toLowerCase()),
            tabela.driver_name.toLowerCase().includes(searchTerms.driverName.toLowerCase()),
            (vehicle && vehicle.route.toLowerCase().includes(searchTerms.routeName.toLowerCase())),
            tabela.driver_phone.includes(searchTerms.driverPhone),
            tabela.passenger_count.toString().includes(searchTerms.passengerCount),
            getVehiclePlate(tabela.return_vehicle_id).toLowerCase().includes(searchTerms.returnVehiclePlate.toLowerCase()),
            getDriverName(tabela.return_driver_id).toLowerCase().includes(searchTerms.returnDriverName.toLowerCase())
        ];
        return routeMatch && searchMatches.every(Boolean);
    });

    const handlePrint = async (tabela) => {
        try {
            const response = await axios.get(`/get_tabela_passengers.php?tabela_id=${tabela.id}`);
            const tabelaPassengersData = Array.isArray(response.data) ? response.data : [];

            const passengersWithDetails = tabelaPassengersData.map(passenger => ({
                ...passenger,
                class: passenger.class || 'Bilinmiyor',
                blood_type: passenger.bloodType || 'Bilinmiyor'
            }));

            generateTabelaExcel(tabela, vehicles, drivers, passengersWithDetails);
        } catch (error) {
            console.error('Error fetching tabela passengers:', error);
        }
    };

    const scrollTabsLeft = () => {
        routeTabsRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    };

    const scrollTabsRight = () => {
        routeTabsRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    };

    const startDrag = (e) => {
        isDragging.current = true;
        startX.current = e.pageX - routeTabsRef.current.offsetLeft;
        scrollLeft.current = routeTabsRef.current.scrollLeft;
    };

    const onDrag = (e) => {
        if (!isDragging.current) return;
        e.preventDefault();
        const x = e.pageX - routeTabsRef.current.offsetLeft;
        const walk = (x - startX.current) * 2;
        routeTabsRef.current.scrollLeft = scrollLeft.current - walk;
    };

    const endDrag = () => {
        isDragging.current = false;
    };

    return (
        <div className="tabela-list-modal">
            <div className="global-modal-header-container">
                <h2>Tabela Listesi</h2>
                <span className="global-modal-header-close-button" onClick={onClose}>&times;</span>
            </div>
            <div className="tabela-list-modal-content">
                <div className="route-tabs-container">
                    <FaAngleDoubleLeft className='route-tabs-navigation' onClick={scrollTabsLeft} />
                    <div
                        className="route-tabs"
                        ref={routeTabsRef}
                        onMouseDown={startDrag}
                        onMouseMove={onDrag}
                        onMouseUp={endDrag}
                        onMouseLeave={endDrag}
                    >
                        {routes.map(route => (
                            <button
                                key={route.id}
                                className={`route-tab ${selectedRoute === route.id ? 'active' : ''}`}
                                onClick={() => handleRouteClick(route.id)}
                            >
                                {route.name}
                            </button>
                        ))}
                    </div>
                    <FaAngleDoubleRight className='route-tabs-navigation' onClick={scrollTabsRight} />
                </div>
                <div className='route-tabs-navigation-selected-item-container'>
                    {selectedRoute && (
                        <div className="route-filter-chip">
                            <span>{routes.find(route => route.id === selectedRoute)?.name}</span>
                            <FaTimes className="route-filter-clear" onClick={clearRouteFilter} />
                        </div>
                    )}
                </div>
                <div className="table-wrapper">
                    <table className='tabela-list-modal-table'>
                        <thead>
                            <tr>
                                <th>
                                    <div className="th-header">
                                        <span>Tabela Adı</span>
                                        <FaSortAlphaDown
                                            onClick={sortTabelaName}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Ara..."
                                        value={searchTerms.tabelaName}
                                        onChange={(e) => handleSearchChange('tabelaName', e.target.value)}
                                        className="table-search-input"
                                    />
                                </th>
                                <th>
                                    <span>Plaka</span>
                                    <input
                                        type="text"
                                        placeholder="Ara..."
                                        value={searchTerms.vehiclePlate}
                                        onChange={(e) => handleSearchChange('vehiclePlate', e.target.value)}
                                        className="table-search-input"
                                    />
                                </th>
                                <th>
                                    <span>Şoför</span>
                                    <input
                                        type="text"
                                        placeholder="Ara..."
                                        value={searchTerms.driverName}
                                        onChange={(e) => handleSearchChange('driverName', e.target.value)}
                                        className="table-search-input"
                                    />
                                </th>
                                <th>
                                    <span>Güzergah Adı</span>
                                    <input
                                        type="text"
                                        placeholder="Ara..."
                                        value={searchTerms.routeName}
                                        onChange={(e) => handleSearchChange('routeName', e.target.value)}
                                        className="table-search-input"
                                    />
                                </th>
                                <th>
                                    <span>Şoför Telefonu</span>
                                    <input
                                        type="text"
                                        placeholder="Ara..."
                                        value={searchTerms.driverPhone}
                                        onChange={(e) => handleSearchChange('driverPhone', e.target.value)}
                                        className="table-search-input"
                                    />
                                </th>
                                <th>
                                    <span>Yolcu Sayısı</span>
                                    <input
                                        type="text"
                                        placeholder="Ara..."
                                        value={searchTerms.passengerCount}
                                        onChange={(e) => handleSearchChange('passengerCount', e.target.value)}
                                        className="table-search-input"
                                    />
                                </th>
                                <th>
                                    <span>Dönüş Plakası</span>
                                    <input
                                        type="text"
                                        placeholder="Ara..."
                                        value={searchTerms.returnVehiclePlate}
                                        onChange={(e) => handleSearchChange('returnVehiclePlate', e.target.value)}
                                        className="table-search-input"
                                    />
                                </th>
                                <th>
                                    <span>Dönüş Şoförü</span>
                                    <input
                                        type="text"
                                        placeholder="Ara..."
                                        value={searchTerms.returnDriverName}
                                        onChange={(e) => handleSearchChange('returnDriverName', e.target.value)}
                                        className="table-search-input"
                                    />
                                </th>
                                <th>İşlemler</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTabelas.map(tabela => (
                                <tr key={tabela.id}>
                                    <td>{tabela.name}</td>
                                    <td>{tabela.vehicle_plate}</td>
                                    <td>{tabela.driver_name}</td>
                                    <td>{getVehicleRoute(tabela.vehicle_id)}</td>
                                    <td>{tabela.driver_phone}</td>
                                    <td>{`${tabela.passenger_count}/${getVehicleCapacity(tabela.vehicle_id)}`}</td>
                                    <td>{getVehiclePlate(tabela.return_vehicle_id)}</td>
                                    <td>{getDriverName(tabela.return_driver_id)}</td>
                                    <td className="actions">
                                        <button className="icon-button" onClick={() => onTabelaEdit(tabela)}><FaEdit /></button>
                                        <button className="icon-button" onClick={() => confirmDelete(tabela.id)}><FaTrashAlt /></button>
                                        <button className="icon-button" onClick={() => handlePrint(tabela)}><FaPrint /></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TabelaList;
