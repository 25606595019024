import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const downloadExcel = (workbook, filename) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, filename);
    });
};

export const generateTabelaExcel = (tabela, vehicles, drivers, tabelaPassengersData) => {
    const vehicle = vehicles.find(v => v.id === tabela.vehicle_id);
    const driver = drivers.find(d => d.id === tabela.driver_id);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Tabela', { pageSetup: { orientation: 'landscape' } });

    const borderStyle = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    const addBorder = (cell) => {
        cell.border = borderStyle;
    };

    worksheet.mergeCells('A1:F1');
    const cellA1 = worksheet.getCell('A1');
    cellA1.value = "2023 / 2024 ÖĞRETİM YILI";
    cellA1.alignment = { horizontal: 'center', vertical: 'middle' };
    cellA1.font = { bold: true };
    addBorder(cellA1);

    worksheet.mergeCells('A2:F2');
    const cellA2 = worksheet.getCell('A2');
    cellA2.value = tabela.route_name; // route_name kullanılıyor
    cellA2.alignment = { horizontal: 'center', vertical: 'middle' };
    cellA2.font = { bold: true };
    addBorder(cellA2);

    worksheet.mergeCells('A3:F3');
    const cellA3 = worksheet.getCell('A3');
    cellA3.value = "SERVİS HİZMETİ VERİLEN ÖĞRENCİLERİN LİSTESİ";
    cellA3.alignment = { horizontal: 'center', vertical: 'middle' };
    cellA3.font = { bold: true };
    addBorder(cellA3);

    worksheet.mergeCells('A4:F4');
    const cellA4 = worksheet.getCell('A4');
    cellA4.value = ''; // Boş satır
    addBorder(cellA4);

    worksheet.mergeCells('A5:F6');  // 5. ve 6. satırı birleştir
    const cellA5 = worksheet.getCell('A5');
    cellA5.value = `TABELA NO: ${tabela.name}`;
    cellA5.alignment = { horizontal: 'center', vertical: 'middle' };
    cellA5.font = { bold: true, color: { argb: 'FFFFFFFF' } };
    cellA5.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF000000' } };
    addBorder(cellA5);

    worksheet.mergeCells('A7:F7');
    const cellA7 = worksheet.getCell('A7');
    cellA7.value = ''; // Boş satır
    addBorder(cellA7);

    worksheet.mergeCells('A8:F8');
    const cellA8 = worksheet.getCell('A8');
    cellA8.value = vehicle ? vehicle.plate : 'BELİRTİLMEDİ';
    cellA8.alignment = { horizontal: 'center', vertical: 'middle' };
    cellA8.font = { bold: true, color: { argb: 'FFFFFFFF' } };
    cellA8.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF000000' } };
    addBorder(cellA8);

    worksheet.mergeCells('A9:F9');
    const cellA9 = worksheet.getCell('A9');
    cellA9.value = ''; // Boş satır
    addBorder(cellA9);

    worksheet.mergeCells('A10:C10');
    const cellA10 = worksheet.getCell('A10');
    cellA10.value = `ARAÇ SAHİBİ ADI SOYADI: ${vehicle ? vehicle.owner_name : 'BELİRTİLMEDİ'}`;
    cellA10.font = { bold: true };
    addBorder(cellA10);

    worksheet.mergeCells('D10:F10');
    const cellD10 = worksheet.getCell('D10');
    cellD10.value = `ARAÇ SAHİBİ CEP TELEFONU: ${vehicle ? vehicle.owner_phone : 'BELİRTİLMEDİ'}`;
    cellD10.font = { bold: true };
    addBorder(cellD10);

    worksheet.mergeCells('A11:C11');
    const cellA11 = worksheet.getCell('A11');
    cellA11.value = `ŞOFÖR ADI SOYADI: ${driver ? driver.name : 'BELİRTİLMEDİ'}`;
    cellA11.font = { bold: true };
    addBorder(cellA11);

    worksheet.mergeCells('D11:F11');
    const cellD11 = worksheet.getCell('D11');
    cellD11.value = `ŞOFÖR CEP TELEFONU: ${driver ? driver.phone : 'BELİRTİLMEDİ'}`;
    cellD11.font = { bold: true };
    addBorder(cellD11);

    worksheet.mergeCells('A12:F12');
    const cellA12 = worksheet.getCell('A12');
    cellA12.value = `GÜZERGAH: ${vehicle ? vehicle.route : 'BELİRTİLMEDİ'}`;
    cellA12.font = { bold: true };
    addBorder(cellA12);

    worksheet.addRow([]);
    worksheet.addRow(["S.N", "SINIFI", "KAN GRUBU", "ADRESİ", "TELEFON", "ADI SOYADI"]).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorder(cell);
    });

    tabelaPassengersData.forEach((passenger, index) => {
        const row = worksheet.addRow([
            index + 1,
            passenger.class || '',
            passenger.bloodType || '',
            passenger.address || 'Adres verisi girilmemiş',
            passenger.phone || '',
            passenger.name || ''
        ]);
        row.eachCell(addBorder);
    });

    // Sütun genişliklerini ayarlama
    worksheet.columns = [
        { key: 'A', width: 5 },
        { key: 'B', width: 20 },
        { key: 'C', width: 20 },
        { key: 'D', width: 50 },
        { key: 'E', width: 15 },
        { key: 'F', width: 30 }  // F sütun genişliğini artırma
    ];

    downloadExcel(workbook, `${tabela.name}.xlsx`);
};
