import React, { useState, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import axios from '../axiosConfig';
import NearbyCustomersModal from './NearbyCustomersModal';
import '../style/MapComponent.css';

const mapContainerStyle = {
  width: '100%',
  height: '90vh',
};

const center = {
  lat: 39.65230096533126,
  lng: 27.89035407376169,
};

const MapComponent = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [nearbyCustomers, setNearbyCustomers] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get('/get_customers.php');
        const validCustomers = response.data.filter(customer => customer.latitude && customer.longitude);
        setCustomers(validCustomers);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  const haversineDistance = (coords1, coords2) => {
    const toRad = (x) => (x * Math.PI) / 180;

    const lat1 = coords1.lat;
    const lon1 = coords1.lng;
    const lat2 = coords2.lat;
    const lon2 = coords2.lng;

    const R = 6371; // Dünya'nın yarıçapı (km)
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  const handleMarkerClick = (customer) => {
    setSelectedCustomer(customer);
    const nearby = customers.filter(c => {
      const distance = haversineDistance(
        { lat: parseFloat(customer.latitude), lng: parseFloat(customer.longitude) },
        { lat: parseFloat(c.latitude), lng: parseFloat(c.longitude) }
      );
      return distance <= 2 && c.route_id === customer.route_id && c.customer_type === customer.customer_type;
    });
    setNearbyCustomers(nearby);
  };

  const iconUrl = '/images/icon/bus-stop.png'; 

  if (loadError) return <div>Hata Yüklenemedi</div>;
  if (!isLoaded) return <div>Yükleniyor...</div>;

  return (
    <div className="map-container">
      <GoogleMap mapContainerStyle={mapContainerStyle} zoom={13} center={center}>
        {customers.map((customer) => (
          <Marker
            key={customer.id}
            position={{ lat: parseFloat(customer.latitude), lng: parseFloat(customer.longitude) }}
            title={customer.name}
            icon={{
              url: iconUrl, // İkon dosyasının yolunu belirtin
              scaledSize: new window.google.maps.Size(40, 40), // İkon boyutu
            }}
            onClick={() => handleMarkerClick(customer)}
          />
        ))}
      </GoogleMap>
      {selectedCustomer && (
        <NearbyCustomersModal
          selectedCustomer={selectedCustomer}
          nearbyCustomers={nearbyCustomers}
          onClose={() => {
            setSelectedCustomer(null);
            setNearbyCustomers([]);
          }}
          onTabelaCreated={() => {
            setSelectedCustomer(null);
            setNearbyCustomers([]);
            // Tabela oluşturulduktan sonra yapılacak işlemler
          }}
        />
      )}
    </div>
  );
};

export default MapComponent;
