import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import '../style/TabelaCreateModal.css';

const TabelaCreateModal = ({ onClose, onCreate }) => {
  const [tabelaName, setTabelaName] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [selectedRoute, setSelectedRoute] = useState('');

  useEffect(() => {
    fetchVehicles();
    fetchDrivers();
    fetchRoutes();
  }, []);

  const fetchVehicles = async () => {
    try {
      const response = await axios.get('/get_vehicles.php');
      setVehicles(response.data);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await axios.get('/get_drivers.php');
      setDrivers(response.data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const fetchRoutes = async () => {
    try {
      const response = await axios.get('/get_routes.php');
      setRoutes(response.data);
    } catch (error) {
      console.error('Error fetching routes:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/add_tabela.php', {
        tabela_name: tabelaName,
        vehicle_id: selectedVehicle,
        driver_id: selectedDriver,
        route_id: selectedRoute,
      });
      onCreate(response.data);
    } catch (error) {
      console.error('Error creating tabela:', error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Tabela Oluştur</h2>
        <form onSubmit={handleSubmit}>
          <label>Tabela Adı:</label>
          <input
            type="text"
            value={tabelaName}
            onChange={(e) => setTabelaName(e.target.value)}
            required
          />
          <label>Araç:</label>
          <select
            value={selectedVehicle}
            onChange={(e) => setSelectedVehicle(e.target.value)}
            required
          >
            <option value="">Araç Seçin</option>
            {vehicles.map((vehicle) => (
              <option key={vehicle.id} value={vehicle.id}>
                {vehicle.plate}
              </option>
            ))}
          </select>
          <label>Şoför:</label>
          <select
            value={selectedDriver}
            onChange={(e) => setSelectedDriver(e.target.value)}
          >
            <option value="">Şoför Seçin (isteğe bağlı)</option>
            {drivers.map((driver) => (
              <option key={driver.id} value={driver.id}>
                {driver.name}
              </option>
            ))}
          </select>
          <label>Güzergah:</label>
          <select
            value={selectedRoute}
            onChange={(e) => setSelectedRoute(e.target.value)}
            required
          >
            <option value="">Güzergah Seçin</option>
            {routes.map((route) => (
              <option key={route.id} value={route.id}>
                {route.name}
              </option>
            ))}
          </select>
          <div className="button-group">
            <button type="button" onClick={onClose}>
              İptal
            </button>
            <button type="submit">Kaydet</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TabelaCreateModal;
