import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import '../style/VehicleListModal.css';

const VehicleListModal = ({ onClose }) => {
  const [vehicles, setVehicles] = useState([]);
  const [editingVehicleId, setEditingVehicleId] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [searchTerms, setSearchTerms] = useState({
    plate: '',
    capacity: '',
    driver_name: '',
    driver_phone: '',
    owner_name: '',
    owner_phone: '',
    route: ''
  });

  const fetchVehicles = async () => {
    try {
      const response = await axios.get('/get_vehicles.php');
      setVehicles(response.data);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  const handleEdit = (vehicle) => {
    setEditingVehicleId(vehicle.id);
    setEditValues(vehicle);
  };

  const handleSaveVehicle = async () => {
    try {
      const response = await axios.post('/update_vehicle.php', editValues);
      setVehicles((prevVehicles) =>
        prevVehicles.map((vehicle) =>
          vehicle.id === response.data.id ? response.data : vehicle
        )
      );
      setEditingVehicleId(null);
    } catch (error) {
      console.error('Error updating vehicle:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingVehicleId(null);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Bu aracı silmek istediğinizden emin misiniz?')) {
      try {
        await axios.post('/delete_vehicle.php', { id });
        fetchVehicles();
      } catch (error) {
        console.error('Error deleting vehicle:', error);
      }
    }
  };

  const handleSearchChange = (key, value) => {
    setSearchTerms(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const filteredVehicles = vehicles.filter(vehicle => {
    return (
      (vehicle.plate && vehicle.plate.toLowerCase().includes(searchTerms.plate.toLowerCase())) &&
      (vehicle.capacity && vehicle.capacity.toString().includes(searchTerms.capacity)) &&
      (vehicle.driver_name && vehicle.driver_name.toLowerCase().includes(searchTerms.driver_name.toLowerCase())) &&
      (vehicle.driver_phone && vehicle.driver_phone.includes(searchTerms.driver_phone)) &&
      (vehicle.owner_name && vehicle.owner_name.toLowerCase().includes(searchTerms.owner_name.toLowerCase())) &&
      (vehicle.owner_phone && vehicle.owner_phone.includes(searchTerms.owner_phone)) &&
      (vehicle.route && vehicle.route.toLowerCase().includes(searchTerms.route.toLowerCase()))
    );
  });

  return (
    <div className="modal-vehicle-list">
      <div className="modal-content-vehicle-list">
        <div className="global-modal-header-container">
          <h2>Araç Listesi</h2>
          <span className="global-modal-header-close-button" onClick={onClose}>&times;</span>
        </div>
        <div className='table-vehicle-list-container'>
          <div className='table-vehicle-top'></div>
          <table className='table-vehicle-list'>
            <thead>
              <tr>
                <th>
                  Plaka
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.plate}
                    onChange={(e) => handleSearchChange('plate', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  Kapasite
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.capacity}
                    onChange={(e) => handleSearchChange('capacity', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  Şoför
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.driver_name}
                    onChange={(e) => handleSearchChange('driver_name', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  Şoför Telefonu
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.driver_phone}
                    onChange={(e) => handleSearchChange('driver_phone', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  Araç Sahibi
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.owner_name}
                    onChange={(e) => handleSearchChange('owner_name', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  Araç Sahibi Telefonu
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.owner_phone}
                    onChange={(e) => handleSearchChange('owner_phone', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>
                  Güzergah
                  <input
                    type="text"
                    placeholder="Ara..."
                    value={searchTerms.route}
                    onChange={(e) => handleSearchChange('route', e.target.value)}
                    className="table-search-input"
                  />
                </th>
                <th>İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {filteredVehicles.map((vehicle) => (
                <tr key={vehicle.id} className={editingVehicleId === vehicle.id ? 'editing-row' : ''}>
                  <td>
                    {editingVehicleId === vehicle.id ? (
                      <input
                        type="text"
                        value={editValues.plate}
                        onChange={(e) => setEditValues({ ...editValues, plate: e.target.value })}
                      />
                    ) : (
                      vehicle.plate
                    )}
                  </td>
                  <td>
                    {editingVehicleId === vehicle.id ? (
                      <input
                        type="text"
                        value={editValues.capacity}
                        onChange={(e) => setEditValues({ ...editValues, capacity: e.target.value })}
                      />
                    ) : (
                      vehicle.capacity
                    )}
                  </td>
                  <td>
                    {editingVehicleId === vehicle.id ? (
                      <input
                        type="text"
                        value={editValues.driver_name}
                        onChange={(e) => setEditValues({ ...editValues, driver_name: e.target.value })}
                      />
                    ) : (
                      vehicle.driver_name || '-'
                    )}
                  </td>
                  <td>
                    {editingVehicleId === vehicle.id ? (
                      <input
                        type="text"
                        value={editValues.driver_phone}
                        onChange={(e) => setEditValues({ ...editValues, driver_phone: e.target.value })}
                      />
                    ) : (
                      vehicle.driver_phone || '-'
                    )}
                  </td>
                  <td>
                    {editingVehicleId === vehicle.id ? (
                      <input
                        type="text"
                        value={editValues.owner_name}
                        onChange={(e) => setEditValues({ ...editValues, owner_name: e.target.value })}
                      />
                    ) : (
                      vehicle.owner_name || '-'
                    )}
                  </td>
                  <td>
                    {editingVehicleId === vehicle.id ? (
                      <input
                        type="text"
                        value={editValues.owner_phone}
                        onChange={(e) => setEditValues({ ...editValues, owner_phone: e.target.value })}
                      />
                    ) : (
                      vehicle.owner_phone || '-'
                    )}
                  </td>
                  <td>
                    {editingVehicleId === vehicle.id ? (
                      <input
                        type="text"
                        value={editValues.route}
                        onChange={(e) => setEditValues({ ...editValues, route: e.target.value })}
                      />
                    ) : (
                      vehicle.route || '-'
                    )}
                  </td>
                  <td>
                    {editingVehicleId === vehicle.id ? (
                      <>
                        <button onClick={handleSaveVehicle}>Kaydet</button>
                        <button onClick={handleCancelEdit}>İptal</button>
                      </>
                    ) : (
                      <>
                        <button onClick={() => handleEdit(vehicle)}>Düzenle</button>
                        <button onClick={() => handleDelete(vehicle.id)}>Sil</button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VehicleListModal;
