import React, { useState, useEffect, useRef } from 'react';
import axios from '../axiosConfig';
import { GoogleMap, DirectionsRenderer, useLoadScript } from '@react-google-maps/api';
import '../style/NearbyCustomersModal.css';

const mapContainerStyle = {
    width: '100%',
    height: '300px',
};

const center = {
    lat: 39.65230096533126,
    lng: 27.89035407376169,
};

const NearbyCustomersModal = ({ selectedCustomer, nearbyCustomers, onClose, onTabelaCreated }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const [tabelaName, setTabelaName] = useState('');
    const [vehicleId, setVehicleId] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [driverId, setDriverId] = useState('');
    const [drivers, setDrivers] = useState([]);
    const [directions, setDirections] = useState(null);
    const [activeCustomers, setActiveCustomers] = useState([selectedCustomer, ...nearbyCustomers.filter(c => c.id !== selectedCustomer.id)]);
    const [removedCustomers, setRemovedCustomers] = useState([]);
    const [hoveredCustomer, setHoveredCustomer] = useState(null);
    const [routes, setRoutes] = useState([]);
    const mapRef = useRef(null);

    useEffect(() => {
        fetchVehicles();
        fetchDrivers();
        fetchRoutes();
    }, [isLoaded, nearbyCustomers]);

    useEffect(() => {
        if (routes.length > 0) {
            calculateRoute();
        }
    }, [activeCustomers, routes]);

    const fetchVehicles = async () => {
        try {
            const response = await axios.get('/get_vehicles.php');
            setVehicles(response.data);
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        }
    };

    const fetchDrivers = async () => {
        try {
            const response = await axios.get('/get_drivers.php');
            setDrivers(response.data);
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };

    const fetchRoutes = async () => {
        try {
            const response = await axios.get('/get_routes.php');
            console.log("Fetched Routes:", response.data); // Bu satırı ekleyerek fetched routes verilerini kontrol edin
            setRoutes(response.data);
        } catch (error) {
            console.error('Error fetching routes:', error);
        }
    };
    

    const handleVehicleChange = (e) => {
        const selectedVehicle = vehicles.find(vehicle => vehicle.id.toString() === e.target.value);
        setSelectedVehicle(selectedVehicle);
        setVehicleId(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const tabelaResponse = await axios.post('/add_tabela.php', {
                tabela_name: tabelaName,
                vehicle_id: vehicleId,
                driver_id: driverId
            });

            const tabelaId = tabelaResponse.data.id;

            // Yakın yolcuları ve seçilen yolcuyu ekle
            const passengers = activeCustomers.map(customer => customer.id);

            await axios.post('/add_tabela_customers.php', {
                tabela_id: tabelaId,
                passengers
            });

            onTabelaCreated();
            onClose();
        } catch (error) {
            console.error('Error creating tabela:', error);
        }
    };

    const haversineDistance = (coords1, coords2) => {
        const toRad = (x) => (x * Math.PI) / 180;

        const lat1 = coords1.lat;
        const lon1 = coords1.lng;
        const lat2 = coords2.lat;
        const lon2 = coords2.lng;

        const R = 6371; // Dünya'nın yarıçapı (km)
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    };

    const calculateRoute = () => {
        if (!isLoaded || !window.google || !selectedCustomer || !routes) return;
    
        console.log("Selected Customer Route ID:", selectedCustomer.route_id);
        console.log("Routes:", routes);
    
        // Route ID'si üzerinden route bilgilerini bulalım
        const routeLocation = routes.find(route => route.id === selectedCustomer.route_id.toString());
    
        // Eğer routeLocation undefined ise, devam etmeyelim
        if (!routeLocation) {
            console.error('Route location is undefined');
            return;
        }
    
        const waypoints = activeCustomers.map(c => ({
            location: { lat: parseFloat(c.latitude), lng: parseFloat(c.longitude) },
            stopover: true
        }));
    
        if (routeLocation) {
            // Ortak konumu waypointlerin sonuna ekleyin
            waypoints.push({
                location: { lat: parseFloat(routeLocation.latitude), lng: parseFloat(routeLocation.longitude) },
                stopover: true
            });
        }
    
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin: { lat: parseFloat(selectedCustomer.latitude), lng: parseFloat(selectedCustomer.longitude) },
                destination: routeLocation
                    ? { lat: parseFloat(routeLocation.latitude), lng: parseFloat(routeLocation.longitude) }
                    : { lat: parseFloat(selectedCustomer.latitude), lng: parseFloat(selectedCustomer.longitude) },
                waypoints,
                optimizeWaypoints: true,
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`Error fetching directions ${result}`);
                }
            }
        );
    };
    
    
    
    

    const removeCustomer = (customer) => {
        setActiveCustomers(activeCustomers.filter(c => c.id !== customer.id));
        setRemovedCustomers([...removedCustomers, customer]);
    };

    const addCustomer = (customer) => {
        setRemovedCustomers(removedCustomers.filter(c => c.id !== customer.id));
        setActiveCustomers([...activeCustomers, customer]);
    };

    const getRouteName = (routeId) => {
        const route = routes.find(route => route.id === routeId);
        return route ? route.name : 'Bilinmiyor';
    };

    if (loadError) return <div>Hata Yüklenemedi</div>;
    if (!isLoaded) return <div>Yükleniyor...</div>;

    return (
        <div className="nearby-modal">
            <div className="nearby-modal-content">
                <h3>Tabela Oluştur</h3>
                <form onSubmit={handleSubmit} className='nearby-form'>
                    <input
                        type="text"
                        placeholder="Tabela Adı"
                        value={tabelaName}
                        onChange={(e) => setTabelaName(e.target.value)}
                        required
                    />
                    <select value={vehicleId} onChange={handleVehicleChange} required>
                        <option value="">Araç Seçin</option>
                        {vehicles.map((vehicle) => (
                            <option key={vehicle.id} value={vehicle.id}>
                                {vehicle.plate} - Kapasite: {vehicle.capacity}
                            </option>
                        ))}
                    </select>
                    <select value={driverId} onChange={(e) => setDriverId(e.target.value)}>
                        <option value="">Şoför Seçin (isteğe bağlı)</option>
                        {drivers.map((driver) => (
                            <option key={driver.id} value={driver.id}>
                                {driver.name} - {driver.phone}
                            </option>
                        ))}
                    </select>

                    <div className="nearby-button-group">
                        <button className='nearby-button-group-button-cancel' type="button" onClick={onClose}>İptal</button>
                        <button className='nearby-button-group-button-save' type="submit">Kaydet</button>
                    </div>
                </form>
                <div className="nearby-map-container">
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={13}
                        center={center}
                        onLoad={ref => (mapRef.current = ref)}
                    >
                        {directions && <DirectionsRenderer directions={directions} />}
                    </GoogleMap>
                </div>
                <div>
                    <ul className='nearby-list-passenger-lu'>
                        {activeCustomers.map(c => {
                            const distance = haversineDistance(
                                { lat: parseFloat(selectedCustomer.latitude), lng: parseFloat(selectedCustomer.longitude) },
                                { lat: parseFloat(c.latitude), lng: parseFloat(c.longitude) }
                            ).toFixed(2);
                            return (
                                <li
                                    key={c.id}
                                    className='nearby-list-passenger-li'
                                    onMouseEnter={() => setHoveredCustomer(c)}
                                    onMouseLeave={() => setHoveredCustomer(null)}
                                >
                                    {c.name} /<p > {distance} km</p>
                                    {c.id !== selectedCustomer.id && (
                                        <button onClick={() => removeCustomer(c)} className="nearby-remove-button">x</button>
                                    )}
                                    {hoveredCustomer === c && (
                                        <div className="hover-info">
                                            <p><strong>İsim:</strong> {c.name} {c.surname}</p>
                                            <p style={{textWrap:"wrap"}}><strong>Adres:</strong> {c.address}</p>
                                            <p><strong>Telefon:</strong> {c.phone}</p>
                                            <p><strong>Kan Grubu:</strong> {c.blood_type}</p>
                                            <p><strong>Güzergah:</strong> {getRouteName(c.route_id)}</p>
                                            <p><strong>Yolcu Tipi:</strong> {c.customer_type}</p>
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                    {removedCustomers.length > 0 && (
                        <>
                            <hr />
                            <h4>Çıkarılan Yolcular:</h4>
                            <ul className='nearby-remove-passenger'>
                                {removedCustomers.map(c => (
                                    <li key={c.id}>
                                        {c.name}
                                        <button onClick={() => addCustomer(c)} className="nearby-add-button">+</button>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NearbyCustomersModal;
