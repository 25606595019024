import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import '../style/NewVehicleModal.css';
import NewDriverModal from './NewDriverModal'; // Şoför ekleme modalı

const NewVehicleModal = ({ onClose }) => {
  const [plate, setPlate] = useState('');
  const [capacity, setCapacity] = useState('28+1');
  const [driverId, setDriverId] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerPhone, setOwnerPhone] = useState('');
  const [route, setRoute] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [showNewDriverModal, setShowNewDriverModal] = useState(false);

  const fetchDrivers = async () => {
    try {
      const response = await axios.get('/get_drivers.php');
      setDrivers(response.data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  const handleAddDriverClick = () => {
    setShowNewDriverModal(true);
  };

  const handleCloseNewDriverModal = () => {
    setShowNewDriverModal(false);
    fetchDrivers(); // Şoför modalı kapatıldığında şoför listesini güncelle
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/add_vehicle.php', {
        plate,
        capacity,
        driver_id: driverId || null, // Şoför isteğe bağlı olduğu için null değeri kullanılabilir
        owner_name: ownerName,
        owner_phone: ownerPhone,
        route,
      });
      onClose();
    } catch (error) {
      console.error('Error adding vehicle:', error);
    }
  };

  return (
    <div className="modal-vehicle">
      <div className="modal-content-vehicle">
        <h2>Yeni Araç Ekle</h2>
        <form onSubmit={handleSubmit} className="form-vehicle">
          <input
            type="text"
            placeholder="Plaka"
            value={plate}
            onChange={(e) => setPlate(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Kapasite (örn. 28+1)"
            value={capacity}
            onChange={(e) => setCapacity(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Araç Sahibinin Adı"
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Araç Sahibinin Telefonu"
            value={ownerPhone}
            onChange={(e) => setOwnerPhone(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Güzergah"
            value={route}
            onChange={(e) => setRoute(e.target.value)}
            required
          />
          <select
            value={driverId}
            onChange={(e) => setDriverId(e.target.value)}
          >
            <option value="">Şoför Seçin (isteğe bağlı)</option>
            {drivers.map((driver) => (
              <option key={driver.id} value={driver.id}>
                {driver.name} - {driver.phone}
              </option>
            ))}
          </select>
          <button type="button" onClick={handleAddDriverClick} className="button-item-vehicle">
            Yeni Şoför Ekle
          </button>
          <div className="button-group-vehicle">
            <button className="button-item-vehicle" type="button" onClick={onClose}>İptal</button>
            <button className="button-item-vehicle" type="submit">Kaydet</button>
          </div>
        </form>
        {showNewDriverModal && (
          <NewDriverModal onClose={handleCloseNewDriverModal} />
        )}
      </div>
    </div>
  );
};

export default NewVehicleModal;
