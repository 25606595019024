import React, { useState, useEffect } from 'react';
import MapComponent from './components/MapComponent';
import LoginModal from './components/LoginModal';
import SettingsMenu from './components/SettingsMenu';
import SearchBar from './components/SearchBar';
import VehicleList from './components/VehicleList';
import './App.css';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  const handleCustomerAdded = (newCustomer) => {
    setCustomers([...customers, newCustomer]);
  };

  const handleCustomerUpdated = (updatedCustomer) => {
    setCustomers((prevCustomers) =>
      prevCustomers.map((customer) =>
        customer.id === updatedCustomer.id ? updatedCustomer : customer
      )
    );
  };

  if (!isLoggedIn) {
    return <LoginModal onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <div className="app">
      <div className='header'>
        <SettingsMenu onLogout={handleLogout} />
        <SearchBar onCustomerAdded={handleCustomerAdded} onCustomerUpdated={handleCustomerUpdated} />
      </div>
      <div className="main-content">
        <MapComponent />
        <VehicleList /> 
      </div>
      <div className='footer'></div>
    </div>
  );
};

export default App;
