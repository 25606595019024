import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Select from 'react-select';
import '../style/NewCustomerModal.css';
import MapModal from './MapModal';
import NewRouteModal from './NewRouteModal';

const NewCustomerModal = ({ onClose, onCustomerAdded }) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [secondPhone, setSecondPhone] = useState('');
    const [address, setAddress] = useState('');
    const [classInput, setClassInput] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [locationUrl, setLocationUrl] = useState('');
    const [showMapModal, setShowMapModal] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [showNewRouteModal, setShowNewRouteModal] = useState(false);

    const [allPassengers, setAllPassengers] = useState([]);
    const [matchedRecords, setMatchedRecords] = useState([]);
    const [selectedTabelaId, setSelectedTabelaId] = useState(null);
    const [newCustomerId, setNewCustomerId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [showMatchedRecords, setShowMatchedRecords] = useState(false);

    useEffect(() => {
        fetchRoutes();
        fetchAllTabelaPassengers();
    }, []);

    const fetchRoutes = async () => {
        try {
            const response = await axios.get('/get_routes.php');
            const formattedRoutes = response.data.map(route => ({
                value: route.id,
                label: route.name
            }));
            setRoutes(formattedRoutes);
        } catch (error) {
            console.error('Error fetching routes:', error);
        }
    };

    const fetchAllTabelaPassengers = async () => {
        try {
            const tabelasResponse = await axios.get('/get_tabelas.php');
            const tabelas = tabelasResponse.data;

            const allPassengers = [];
            for (const tabela of tabelas) {
                const passengersResponse = await axios.get('/get_tabela_passengers.php', {
                    params: { tabela_id: tabela.id }
                });
                if (passengersResponse.data.message === "Invalid tabela_id") {
                    console.error("Invalid tabela_id:", tabela.id);
                    continue;
                }
                allPassengers.push(...passengersResponse.data);
            }

            setAllPassengers(allPassengers);
        } catch (error) {
            console.error('Error fetching tabela passengers:', error);
        }
    };

    const handleAddressClick = () => {
        setShowMapModal(true);
    };

    const handleMapModalClose = () => {
        setShowMapModal(false);
    };

    const handleNewRouteModalClose = () => {
        setShowNewRouteModal(false);
    };

    const handleAddRoute = async (newRoute) => {
        try {
            const response = await axios.post('/add_route.php', newRoute);
            const addedRoute = { value: response.data.id, label: response.data.name };
            setRoutes(prevRoutes => [...prevRoutes, addedRoute]);
            setSelectedRoute(addedRoute);
        } catch (error) {
            console.error('Error adding route:', error);
        }
    };

    const handleDeleteRoute = async () => {
        try {
            await axios.post('/delete_route.php', { id: selectedRoute.value });
            setRoutes(prevRoutes => prevRoutes.filter(route => route.value !== selectedRoute.value));
            setSelectedRoute(null);
        } catch (error) {
            console.error('Error deleting route:', error);
        }
    };

    const extractLatLngFromUrl = (url) => {
        const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
        const matches = url.match(regex);
        if (matches) {
            return { lat: matches[1], lng: matches[2] };
        }
        return null;
    };

    const handleLocationUrlChange = async (e) => {
        setLocationUrl(e.target.value);
        const coords = extractLatLngFromUrl(e.target.value);
        if (coords) {
            setLatitude(coords.lat);
            setLongitude(coords.lng);
            const geocoder = new window.google.maps.Geocoder();
            const latLng = { lat: parseFloat(coords.lat), lng: parseFloat(coords.lng) };
            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    setAddress(results[0].formatted_address);
                } else {
                    console.error('Geocoder failed due to: ' + status);
                }
            });
        }
    };

    const handleAddressChange = async (e) => {
        const newAddress = e.target.value;
        setAddress(newAddress);

        filterMatchedRecords(newAddress, selectedRoute);
    };

    const handleRouteChange = (selectedOption) => {
        setSelectedRoute(selectedOption);

        filterMatchedRecords(address, selectedOption);
    };

    const calculateSimilarity = (address1, address2) => {
        const address1Words = address1.toLowerCase().split(' ');
        const address2Words = address2.toLowerCase().split(' ');
        const commonWords = address1Words.filter(word => address2Words.includes(word));
        return commonWords.length;
    };

    const filterMatchedRecords = (newAddress, selectedRoute) => {
        if (newAddress.trim() === '' && !selectedRoute) {
            setMatchedRecords([]);
            return;
        }

        const matches = allPassengers.map(passenger => {
            const similarity = calculateSimilarity(newAddress, passenger.address);
            return { ...passenger, similarity };
        }).filter(passenger => (!selectedRoute || passenger.route_name === selectedRoute.label))
          .sort((a, b) => b.similarity - a.similarity);

        setMatchedRecords(matches);
    };

    const handleTabelaAdd = async (tabelaId, customerId) => {
        if (!tabelaId || !customerId) {
            console.error(`Invalid tabelaId (${tabelaId}) or customerId (${customerId})`);
            return;
        }

        console.log(`Adding customer ${customerId} to tabela ${tabelaId}`);
        try {
            const response = await axios.post('/add_tabela_customers.php', {
                tabela_id: tabelaId,
                passengers: [customerId]
            });
            if (response.data.message === 'Tabela and passengers added successfully') {
                alert('Yolcu başarıyla tabelaya eklendi.');
                onClose();
            } else {
                console.error('Error adding passenger to tabela:', response.data.message);
            }
        } catch (error) {
            console.error('Error adding passenger to tabela:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditing) {
                // Güncelleme işlemi
                const response = await axios.post('/update_customer.php', {
                    id: newCustomerId,
                    name,
                    phone,
                    second_phone: secondPhone,
                    address,
                    class: classInput,
                    latitude,
                    longitude,
                    customer_type: 'Öğrenci', // Varsayılan olarak Öğrenci tipi
                    route_id: selectedRoute ? selectedRoute.value : null
                });
                if (response.data.message === "Customer updated successfully.") {
                    alert('Müşteri bilgileri başarıyla güncellendi.');
                    setIsEditing(false);
                    setIsSaved(true);
                } else {
                    console.error('Error updating customer:', response.data.message);
                }
            } else {
                // Ekleme işlemi
                const response = await axios.post('/add_customer.php', {
                    name,
                    phone,
                    second_phone: secondPhone,
                    address,
                    class: classInput,
                    latitude,
                    longitude,
                    customer_type: 'Öğrenci', // Varsayılan olarak Öğrenci tipi
                    route_id: selectedRoute ? selectedRoute.value : null
                });
                if (response.data.message === "Customer added successfully.") {
                    const newCustomerId = response.data.customer.id;
                    setNewCustomerId(newCustomerId);
                    setIsSaved(true);
                    setShowMatchedRecords(true);
                    alert('Müşteri başarıyla eklendi.');
                } else {
                    console.error('Error adding customer:', response.data.message);
                }
            }
            onCustomerAdded();
        } catch (error) {
            console.error('Error adding/updating customer:', error);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
        setIsSaved(false);
    };

    return (
        <div className="new-customer-modal">
            <div className='new-customer-modal-content-right'>
                {showMatchedRecords && matchedRecords.length > 0 ? (
                    <ul>
                        {matchedRecords.map((record, index) => (
                            <li key={index}>
                                <h3>{record.tabela_name}</h3>
                                <p><b>Öğrenci İsmi:</b> {record.name}</p>
                                <p><b>Adres: {record.address}</b></p>
                                <p><b>Okul:</b> {record.route_name}</p>
                                <button onClick={() => {
                                    setSelectedTabelaId(record.tabela_id);
                                    handleTabelaAdd(record.tabela_id, newCustomerId);
                                }} disabled={!isSaved}>Tabelaya Ekle</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Benzer adres bulunamadı.</p>
                )}
            </div>
            <div className="new-customer-modal-content">
                <h2>Yeni Yolcu Ekle</h2>
                <form onSubmit={handleSubmit} className="new-customer-form">
                    <input
                        type="text"
                        placeholder="İsim Soyisim"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        disabled={isSaved}
                    />
                    <input
                        type="number"
                        placeholder="Telefon"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        disabled={isSaved}
                    />
                    <input
                        type="number"
                        placeholder="2. Telefon"
                        value={secondPhone}
                        onChange={(e) => setSecondPhone(e.target.value)}
                        disabled={isSaved}
                    />
                    <div className="new-customer-route-select-container">
                        <Select
                            value={selectedRoute}
                            onChange={handleRouteChange}
                            options={routes}
                            placeholder="Okul seçin"
                            isClearable
                            className='new-customer-route-select-container-select'
                            isDisabled={isSaved}
                        />
                        <button
                            type="button"
                            className={`new-customer-delete-route-button ${selectedRoute ? 'active' : 'inactive'}`}
                            onClick={handleDeleteRoute}
                            disabled={!selectedRoute || isSaved}
                        >
                            Sil
                        </button>
                        <button
                            type="button"
                            onClick={() => setShowNewRouteModal(true)}
                            className={`new-customer-map-button ${isSaved ? 'disabled-button' : ''}`}
                            disabled={isSaved}
                        >
                            +
                        </button>
                    </div>
                    <input
                        type="text"
                        placeholder="Konum URL'si"
                        value={locationUrl}
                        onChange={handleLocationUrlChange}
                        disabled={isSaved}
                    />
                    <textarea
                        className='new-customer-adres-textarea'
                        placeholder="Adres"
                        value={address}
                        onChange={handleAddressChange}
                        required
                        disabled={isSaved}
                    />
                    <button type="button" onClick={handleAddressClick} className={`new-customer-map-button ${isSaved ? 'disabled-button' : ''}`} disabled={isSaved}>
                        Haritadan Seç
                    </button>
                    <input
                        type="hidden"
                        value={latitude}
                        required
                    />
                    <input
                        type="hidden"
                        value={longitude}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Sınıf"
                        value={classInput}
                        onChange={(e) => setClassInput(e.target.value)}
                        required
                        disabled={isSaved}
                    />
                    <div className="modal-forms-bottom-yes-or-no-button-group">
                        <button className="modal-forms-bottom-yes-or-no-button-group-no" onClick={onClose}>İptal / Kapat</button>
                        {isSaved && <button className="modal-forms-bottom-yes-or-no-button-group-edit" type="button" onClick={handleEdit}>Düzenle</button>}
                        <button className="modal-forms-bottom-yes-or-no-button-group-yes" type="submit">{isEditing ? "Değişiklikleri Kaydet" : "Kaydet"}</button>
                    </div>
                </form>
                {showMapModal && (
                    <MapModal
                        onClose={handleMapModalClose}
                        onSelectAddress={({ address, lat, lng }) => {
                            setAddress(address);
                            setLatitude(lat);
                            setLongitude(lng);
                        }}
                    />
                )}
                {showNewRouteModal && (
                    <NewRouteModal
                        onClose={handleNewRouteModalClose}
                        onAddRoute={handleAddRoute}
                    />
                )}
            </div>
        </div>
    );
};

export default NewCustomerModal;
